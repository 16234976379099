import React from "react";
import axios from "axios";
import { useInfiniteQuery, useQuery } from "react-query";
import jsonpAdapter from "../common/axios-jsonp";
import { getSolrVal } from "../catalog/catalogUtils";
import {getNextInList, getPreviousInList, langcode} from "../common/utils";
import { HtmlCustom } from "../common/HtmlCustom";
import {useBiblStore} from "./useBiblStore";
import {usePagerText} from "./usePagerText";

/**
 * UseInfiniteSection : for sections of marked up structured text
 *
 * @param sid
 * @param byPass
 * @returns {UseInfiniteQueryResult<boolean|{next: string, prev: string, page: JSX.Element}, unknown>}
 */
export function useInfiniteSection(sid, byPass = false) {

  // console.log("Sid in use infinite scroll", sid);
  const biblStore = useBiblStore();
  const txtbibl = biblStore.doc;
  const edbibl = biblStore.edBibl;
  const texttoc = biblStore.docToc;

  return useInfiniteQuery(
    ["section", txtbibl?.id, sid],
    ({
       pageParam = sid,
       eb = edbibl,
       tb = txtbibl,
       toc = texttoc
    }) => pagequery(pageParam, eb, tb, toc),
    {
      getPreviousPageParam: (firstPage, allPages) => firstPage.prev,
      getNextPageParam: (lastPage, allPages) => lastPage.next,
    }
  );
}

/**
 * pagequery is the SOLR query function to retrieve text section records (type:textsection)
 *
 * Returns an object with:
 *  page: the text of the page
 *  next: the id of the next page or false
 *  prev: the id of the previous page or false
 *
 * @param sid
 * @param edbibl
 * @param textbibl
 * @returns {Promise<{next: (*|boolean|boolean), prev: (*|boolean|boolean), section: JSX.Element, sid}|boolean>}
 */
const pagequery = async (sid, edbibl, textbibl, toc) => {
  // console.log("in pg query", sid, textbibl);

  if (!sid || sid === "") {
    return false;
  }

  let request = {
    url: process.env.REACT_APP_SOLR_URL,
    adapter: jsonpAdapter,
    callbackParamName: "json.wrf",
    params: {
      q: `section_num_s:${sid}`,
      fq: ["type:textsection", `bibid_s:${textbibl.id}`],
      wt: 'json',
    },
  };
  // console.log("request", request);
  const reply = await axios.request(request).catch(function (e) {
    console.log("error", e);
  });
  let data = reply?.data;
  // console.log("Data in inf section: " + sid, data);
  let secttext = false;
  if (data && data?.response && data.response?.numFound > 0) {
    const sdoc = data.response.docs[0];
    secttext = (Object.keys(sdoc).includes('content_mu'))? sdoc['content_mu'] : sdoc['content'];
  }
  if (Array.isArray(secttext)) {
    secttext = secttext[0];
  }
  // secttext = <HtmlCustom markup={secttext} key={`${textbibl?.id}-${sid}`} />; moved to TextSection
  const retdata = {
    section: secttext,
    sid: sid,
    tid: textbibl?.id,
    next: (toc) ? getNextInList(sid, toc) : false,
    prev: (toc) ? getPreviousInList(sid, toc) : false,
  };
  // console.log("redata next", retdata.next);
  return retdata;
};
