import {useBiblStore} from "../hooks/useBiblStore";
import {useSearchStore} from "../hooks/useSearchStore";
import React, {useEffect, useState} from "react";
import {DynamicTextToc} from "../reader/DynamicTextToc";
import TextToc from "../reader/TextToc";
import {Link} from "react-router-dom";
import {IoArrowBack} from "react-icons/all";
import {CatToc} from "./CatToc";
import {Tab, Tabs} from "react-bootstrap";


export default function TablesOfContent({ biblId, view, biblpassed, hasSections }) {
    const biblStore = useBiblStore();
    const bibl = biblStore.doc;
    const search = useSearchStore();

    let deftab =(["text", "pages", "scans"].includes(view)) ? 'texttoc' : 'cattoc';
    const [tocTab, setTocTab]  = useState(deftab);
    const [hasChaps, setHasChaps] = useState(true);
    const [isTextTab, setIsTextTab] = useState(true);

    useEffect(() => {
        const tabSelected = (isTextTab && ["text", "pages", "scans"].includes(view)) ? 'texttoc' : 'cattoc';
        setTocTab(tabSelected);
        if (bibl?.has_chaps) {
            setHasChaps(bibl?.has_chaps);
        }
    }, [bibl, view, isTextTab]);

    useEffect( () => {
        let newval = false;
        if (!['text', 'volume'].includes(bibl?.subtype)) {
            newval = false;
        } else if (bibl?.sections?.length > 0 || biblStore?.dynamicSections?.length > 0) {
            newval = true;
        }
        setIsTextTab(newval);
    }, [bibl, biblStore]);

    const txttoc = (biblStore?.docToc) ? <DynamicTextToc bibl={bibl} /> : <TextToc view={view} /> ;

    return (<>
        {search.isActive && (
            <div id="c-search-ctls" className="float-right">
                <div className="back"><Link to="/catalog/search" title="Back to results page"><IoArrowBack />
                    Search Results</Link></div>
            </div>
        )
        }
        <h1 className="h3">Table of Contents</h1>
        { !isTextTab && (
            <CatToc selId={biblId} />
        )}
        { isTextTab && (
            <Tabs id="cattoc-tab"
                  className="mb-3"
                  defaultActiveKey={tocTab}
                  onSelect={(k) => setTocTab(k)}
                  transition={false}
            >
                <Tab eventKey="cattoc" title="Catalog">
                    <CatToc selId={biblId} />
                </Tab>

                <Tab eventKey="texttoc" title="Text" >
                    {txttoc}
                </Tab>

            </Tabs>
        )}
    </>)
}
