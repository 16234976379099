import React, {useState} from "react";
import {Overlay, OverlayTrigger} from "react-bootstrap";
import { Popover } from 'react-tiny-popover';
import {getNow} from "./utils";

export function Apparatus({readings}) {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    let lem = '';
    let rdgs = [];
    let note = null;
    // Separate Lemma from other readings
    readings.map((item, itn) => {
        if (item.type === 'lem') {
            lem = item;
        }
        if (item.type === "rdg") {
            rdgs.push( `${item?.wit}: ${item?.text}` );
        }
        if (item.type === "wit" && item?.wit === "note") {
            console.log("Item wit", item);
            note = <p><strong>Note:</strong> {item?.text}</p>;
        }
    });
    let tmk = getNow();
    const pcnt = (<div className="border rounded-lg bg-white p-3">
        <span onClick={() => setIsPopoverOpen(!isPopoverOpen)} className="close-simple-pop">x</span>
        <h1 className="h5 text-center border-dark border-bottom font-italic">Readings</h1>

        <ul className="list-unstyled">
            <li key={`app-${tmk}-main`} ><span className="text-capitalize">{lem?.wit}</span>: {lem?.text}</li>
        {rdgs?.map((rd, rdi) => {
            tmk = (new Date()).getTime();
            return <li key={`app-${tmk}-${rdi}`}>{rd}</li>
        })}
    </ul>{note}</div>);
    return (<Popover
            isOpen={isPopoverOpen}
            positions={['right', 'left', 'top', 'bottom']} // preferred positions by priority
            content={pcnt}
        ><span className="variant pntr bold" onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                title="Click for variants">{lem?.text}</span></Popover>);
}

/**
 * <Overlay
 *                 show={show}
 *             >
 *                 <Popover id="popover-trigger-hover-focus" title="Popover bottom">
 *                     <ul>
 *                         <li key={`app-${tmk}-main`}>Lemma Edition: {lem?.wit}</li>
 *                         {rdgs?.map((rd, rdi) => {
 *                             tmk = (new Date()).getTime();
 *                             return <li key={`app-${tmk}-${rdi}`}>{rd}</li>
 *                         })}
 *                     </ul>
 *                 </Popover>
 *             </Overlay>
 */
