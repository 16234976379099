
/**
 * Highlights a plain title if not using a title from Solr hits which come already highlighted this way.
 *
 * @param title - The title in question
 * @param sstr - The search string of the current search
 * @returns {*} - a react component with the embedded search string highlighted with span[@class='hl']
 * @constructor
 */
export function HighlightTitleHit({title, sstr}) {
    if (Array.isArray(title)) {
        if (title.length !== 1) {
            return title;
        }
        title = title[0];
    }
    const tparts = title.split(sstr);

    return tparts.map((pt, n) => {
        if ( tparts.length === 1 || n === tparts.length - 1) {
            return pt;
        }
        return <span key={`hit-${n}`}>{pt}<span className="hl">{sstr}</span></span>;
    })
}
