import React, {useEffect, useState} from "react";
import "../css/catalog.scss";
import { langcode } from "../../common/utils";
import { getFieldsByString, solrFieldValue } from "../catalogUtils";
import { getBiblAgents } from "./BiblAgents";
import { BiblHead } from "./BiblHead";
import { BiblIdInfo } from "./BiblIdInfo";
import { BiblPhysDesc } from "./BiblPhysDecl";
import { BiblIntelDesc } from "./BiblIntellDecl";
import { BiblProvenance } from "./BiblProvenance";
import { BiblTitles } from "./BiblTitles";
import { BiblSections } from "./BiblSections";
import { BiblMetadata } from "./BiblMetadata";
import { useBiblStore } from "../../hooks/useBiblStore";
import { BiblPubstmt } from "./BiblPubstmt";
import {BiblSectionNav} from "./BiblSectionNav";
import {VolTextTocLink} from "../VolToc";
import {BiblCorresp} from "./BiblCorresp";
import {Col, Container, Row} from "react-bootstrap";

// All Bibl record section types
export const biblSectionTypes = {
  id: "Identification Information",
  titles: "Titles",
  corresp: "Parallel Editions",
  pubstmt: "Publication Statement",
  phys: "Physical Description",
  intel: "Intellectual Description",
  prov: "Provenance",
  toc: "Table of Contents",
  meta: "Metadata",
};

export default function BiblRecord({ customBibl = null }) {
  let bibl = useBiblStore((state) => state.doc);
  if (customBibl !== null) {
    bibl = customBibl;
  }

  // console.log("bibl in bibl record", bibl);
  const isEdition = bibl?.subtype === "edition";
  const isMaster = bibl?.ed?.toLowerCase() === 'master';

  // State for displaying Bibl record sections
  const [selected, setSelected] = useState("all");

  if (!bibl) {
    return null;
  }

  // console.log("The bibl", bibl);

  let biblSections = Object.keys(biblSectionTypes);

  bibl.langcode = langcode(solrFieldValue(bibl?.lang)); // Get general lang for bibl

  const isVisible = (skey) => {
    if (Array.isArray(biblSections) && biblSections.includes(skey)) {
      if (selected === "all" || selected === skey) {
        return true;
      }
    }
    return false;
  };

  // Remove empty sections
  // Check for pubstmt
  if(!isEdition) {
    let myind = biblSections.indexOf("pubstmt");
    biblSections.splice(myind, 1);
  }

  // Check if master
  if(!isMaster) {
    let myind = biblSections.indexOf("corresp");
    biblSections.splice(myind, 1);
  }

  // Check for provenance content
  const agents = getBiblAgents(bibl);
  const coloflds = getFieldsByString(bibl, "colophon_", "pref");
  if (agents?.length === 0 && coloflds?.length === 0) {
    let myind = biblSections.indexOf("prov");
    biblSections.splice(myind, 1);
  }

  // Check for intell decl content
  // console.log("bibl for dox", bibl);
  const doxfields = getFieldsByString(bibl, "dox_", "pref").concat(
    getFieldsByString(bibl, "doxcat_", "pref")
  );
  const objhomfld = getFieldsByString(bibl, "homage-obj", "pref");
  if (doxfields.length === 0 && objhomfld.length === 0) {
    let myind = biblSections.indexOf("intel");
    biblSections.splice(myind, 1);
  }

  // Check for Physical description fields
  let physflds = (bibl?.pfacets) ? [...bibl.pfacets] : [];
  ['vol_', 'pg_', 'sides_'].map( (fpref, fi) => {
    let fbs = getFieldsByString(bibl, fpref, "pref");
    physflds += fbs;
  });

  if (physflds?.length === 0) {
    let myind = biblSections.indexOf("phys");
    biblSections.splice(myind, 1);
  }

  // Check for Sections
  if (!bibl?.sections || bibl.sections?.length === 0) {
    let myind = biblSections.indexOf("toc");
    biblSections.splice(myind, 1);
  }

  if (bibl?.texts) {
    if (!Array.isArray(bibl.texts)) {
      bibl.texts = [bibl.texts];
    }
  }

  if (!bibl) {
    return null;
  }
  // const showTextLink = bibl?.subtype === "text"; // TODO: Need to add code to determine if text exists on an edition basis

  // console.log("Current bibl in bibl Record", bibl);
  return (
    <Container className="c-bibl row" fluid="md">
      <Row>
        <Col className="bibl-info" sm={9}>
          <BiblHead bibl={bibl} />
          {isVisible("id") && <BiblIdInfo bibl={bibl} />}
          {isEdition && isVisible("pubstmt") && (
              <BiblPubstmt bibl={bibl} />
          )}
          {isVisible("titles") && <BiblTitles bibl={bibl} />}
          {isMaster && isVisible("corresp") && (
              <BiblCorresp bibl={bibl} />
          )}
          {isVisible("prov") && <BiblProvenance bibl={bibl} />}
          {isVisible("intel") && <BiblIntelDesc bibl={bibl} />}
          {isVisible("phys") && <BiblPhysDesc bibl={bibl} />}
          {bibl?.subtype === "text" && isVisible("toc") && (
              <BiblSections bibl={bibl} />
          )}
          {bibl?.subtype === "volume" && isVisible("toc") && bibl?.texts?.length > 0 && (
              <>
                <h2>Texts</h2>
                <ul className="textlist">
                  {bibl?.texts.map((t, ti) => {
                    return <li key={`text-list-${ti}`} ><VolTextTocLink text={t} lang={bibl?.langcode} /></li>;
                  })}
                </ul>
              </>
          )}
          {isVisible("meta") && <BiblMetadata bibl={bibl} />}
        </Col>

        <Col>
          <BiblSectionNav
              sections={biblSections}
              selkey={selected}
              setSelected={setSelected}
          />
        </Col>
      </Row>
    </Container>
  );
}

/* old
export function BiblRecordDisplay({ biblId }) {
  // console.log("biblid: ", biblId);
  const query = {
    q: `id:${biblId}`,
    fl: `*,[child limit=1000]`,
  };
  const {
    isLoading: isBiblLoading,
    data: solrRes,
    isError: isBiblError,
    error: biblError,
  } = useSolr(query);

  if (isBiblLoading) {
    return <p>Need a skeleton here!</p>;
  }
  if (isBiblError) {
    return <p>There was an error: {biblError}</p>;
  }
  const biblrec = solrRes.numFound > 0 ? solrRes.docs[0] : false;
  return <BiblRecord bibl={biblrec} />;
}


 */
