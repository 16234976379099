import create from "zustand";
import {parsePagination, zfill} from "../catalog/catalogUtils";

/**
 * usePager: Pager for minimally structure digital texts with only front, body, back sections and paginations.
 * @type {UseStore<{next: function(): void, side: string, visible: [], setLoaded: function(*): void, prev: function(): void, section: string, getPageStr: function(): string, setSection: function(*): void, updateVisible: function(*, *): void, loaded: [], getValue: function(): [*,*,*], vol: number, lastAction: string, getPagePath: function(string=): string, page: number, setPage: function(*, *, *): void}>}
 */
export const usePager = create((set, get) => ({
  vol: 1,
  page: 1,
  side: "b",
  loaded: [],
  section: "", // current section being viewed
  sectpgn: "", // current section pagination
  visible: [],
  lastAction: 'initial',
  min: 1,
  max: 9999,
  total: 9999,
  getPagePath: (ext = "jpg") => {
    const vol = zfill(get().vol * 1, 3);
    const pgstr = get().getPageStr();
    return `v${vol}\\${pgstr}.${ext}`;
  },
  getPageStr: () => {
    const vol = zfill(get().vol * 1, 3);
    const page = zfill(get().page, 4);
    const side = get().side;
    return `v${vol}p${page}${side}`;
  },
  getValue: () => {
    return [get().vol, get().page, get().side];
  },
  next: () => {
    let vol = get().vol;
    let page = get().page;
    let max = get().max;
    let side = get().side;
    if (side === "a") {
      side = "b";
    } else {
      page = page * 1 + 1;
      side = "a";
    }

    if(page > max) { page = max; }

    set((state) => ({
      vol: vol,
      page: page,
      side: side,
      lastAction: 'change',
    }));
  },
  prev: () => {
    let vol = get().vol;
    let page = get().page;
    let side = get().side;
    if (side === "a") {
      side = "b";
      page = page * 1 - 1;
      if (page < 1) {
        page = 1;
      }
    } else {
      side = "a";
    }
    set((state) => ({
      vol: vol,
      page: page,
      side: side,
      lastAction: 'change',
    }));
  },
  setLoaded: (pgs) => {
    set((state) => ({
      loaded: pgs,
    }));
  },
  setPage: (vl, pg, sd) => {
    let la = (get().lastAction == 'initial') ? '' : 'change';
    set((state) => ({
      vol: vl,
      page: pg,
      side: sd,
      lastAction: la,
      visible: [],
    }));
  },
  setRange: (minin, maxin, totalin) => {
    set((state) => ({
      min: minin,
      max: maxin,
      total: totalin
    }));
  },
  setSection: (sect) => {
    const sid = (sect?.chapid_s) ? sect.chapid_s : sect;
    if (typeof(sid) !== "string") {
      console.warn("Improper section ID in pager setSection");
    }
    // if (sect?.texttype === 'pages') { // texttype is set just before call in TextToc.js  See below
    // For paged texts, we have to get the pagination from the section info and display the start page of the section
    const origpgn = sect?.pagination_s || sect;
    let pgn = parsePagination(origpgn);
    const vl = (pgn?.hasvol) ? pgn?.start?.vol : get().vol;
    const pg = pgn?.start?.page || get().page;
    const sd = pgn?.start?.side || get().side;

    set((state) => ({
      section: sid,
      sectpgn: origpgn,
      vol: vl,
      page: pg,
      side: sd,
      visible: [],
      lastAction: 'change',
    }));

    /* Marked up texts now use usePagerText.js
      // For marked up text, whole text is showing, so set section to id and it will scroll into view
      set((state) => ({
        section: sid,
        lastAction: 'section',
      }));
    */
  },
  updateVisible: (action, item) => {
    if (action && item) {
      let vsbl = get().visible;
      if (action === "add" && !vsbl?.includes(item)) {
        vsbl.push(item);
      }
      const iind = vsbl.indexOf(item);
      if (action === "remove" && iind > -1) {
        vsbl.splice(iind, 1);
      }
      vsbl.sort();
      set({ visible: vsbl, lastAction: '' });
    }
  },
}));
