import React, { useEffect, useState } from "react";
import {
  ButtonGroup,
  Container,
  ToggleButton,
} from "react-bootstrap";
import { getTitleByLang } from "./catalogUtils";
import DoxToc from "./DoxToc";
import VolToc from "./VolToc";
import { useBiblStore } from "../hooks/useBiblStore";

export function CatToc({ selId }) {
  const bibl = useBiblStore((state) => state.doc);
  const edbibl = useBiblStore((state) => state.edBibl);
  const toc = useBiblStore((state) => state.toc);
  const setToc = useBiblStore((state) => state.setToc);
  const [doxDisabled, setDoxDisabled] = useState(false);

  const toctypes = [
    { id: "tocdox", label: "Categories", value: "dox" },
    { id: "tocvol", label: "Volumes", value: "vol" },
  ];

  // const [edTitle, setEdTitle] = useState();
  /*
  console.log("Sel id", selId)
  console.log("bibl", bibl);
  console.log("ed bibl", edbibl);
   */

  useEffect(() => {
    if (edbibl?.titles && edbibl?.titles?.length > 0) {
      const edlang = edbibl?.langcode ? edbibl.langcode : "en";
      let newTitle = getTitleByLang(edbibl.titles, edlang);
      // setEdTitle(newTitle);
    }
  }, [edbibl]);
  let isOpen = true;
  const updateToc = (newTocType) => {
    setToc(newTocType);
  };
  const noDoxToc = () => {
    setDoxDisabled(true);
    updateToc('tocvol');
  }

  const currentToc =
    toc === "dox" ? (
      <DoxToc bibl={bibl} selId={selId} openFirst={isOpen} noneCallback={noDoxToc}/>
    ) : (
      <VolToc bibl={bibl} selId={selId} openFirst={isOpen} />
    );


  return (
    <Container className={`c-toc ${toc}`}>
      <ButtonGroup className="c-toc-toggle mb-2">
        {toctypes.map((ttype, idx) => (
          <ToggleButton
            key={idx}
            id={ttype.id}
            type="radio"
            variant="light"
            name="toctype"
            value={ttype.value}
            checked={toc === ttype.value || toc === ttype.id}
            disabled={ttype.id === 'tocdox' && doxDisabled}
            className="toc-type-btn"
            onChange={(e) => updateToc(e.currentTarget.value)}
          >
            {ttype.label}
          </ToggleButton>
        ))}
      </ButtonGroup>
      {currentToc}
    </Container>
  );
}
