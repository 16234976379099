import React, {useEffect} from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import ApiTest from "./catalog/ApiTest";
import CatalogFrame from "./catalog/CatalogFrame";
import CatalogSummary from "./catalog/CatalogSummary";
import NavHead from "./common/NavHead";
import { CollectionSummary } from "./catalog/CollectionSummary";
import {SearchPage} from "./search/SearchPage";

export default function MainRoutes(props) {


    return (
        <BrowserRouter>
            <NavHead />
            <Switch>
                <Route path={"/catalog/:coll/eds"}>
                    <CollectionSummary />
                </Route>

                <Route
                    path={[
                    "/catalog/:coll/:ed/:txtnum/:view/section/:sid",
                    "/catalog/:coll/:ed/:txtnum/:view/:vol/:page",
                    "/catalog/:coll/:ed/:txtnum/:view",
                    "/catalog/:coll/:ed/dox/:dox",
                    "/catalog/:coll/:ed/v:vol",
                    "/catalog/:coll/:ed/:txtnum",
                    "/catalog/:coll/:ed",
                    ]}
                >
                    <CatalogFrame />
                </Route>

                <Route path={"/catalog/search"} >
                    <SearchPage/>
                </Route>

                <Route path={"/catalog"}>
                    <CatalogSummary />
                </Route>

                <Route path={"/"}>
                    <Redirect to="/catalog" />
                </Route>

            </Switch>
        </BrowserRouter>
    );
}
