/** Utility functions **/
import { Iso639M } from "./Iso639M/Iso639M";

/**
 * langcode: get the langcode of the desired length, default to 2 (i.e. translate from 3 to 2 letter or vice versa)
 *  e.g. langcode('bo', 3) returns 'tib', while langcode('tib') returns 'bo'
 * @param lcd
 * @returns {string|*}
 */
export function langcode(lcd, lgth = 2) {
  if (Array.isArray(lcd)) {
    lcd = lcd[0];
  }
  if (lcd?.length === 2) {
    return lgth === 2 ? lcd : Iso639M.convertLangCode(lcd);
  } else if (lcd?.length === 3) {
    return lgth === 3 ? lcd : Iso639M.convertLangCode(lcd);
  }
  return lcd;
}

/**
 * Function uses RegEx on a field name to see if it has any lang code embedded in it
 *
 * @param fnm
 * @returns {boolean}
 */
export function langcodeFromField(fnm) {
  let fieldcode = false;
  const allLangs = Iso639M.getAllCodes();
  for (let ln in allLangs) {
    let lngcode = allLangs[ln];
    let pat = RegExp("_" + lngcode + "(_|$)"); // lang code either followed by _ or at end of string
    if (fnm.match(pat)) {
      fieldcode =
        lngcode?.length === 3 ? Iso639M.convertLangCode(lngcode) : lngcode; // convert 3 letter to 2 letter codes
      break;
    }
  }
  return fieldcode;
}

export function isLangCode(lcd) {
  return Iso639M.langExists(lcd);
}

export function langNameFromCode(lcd) {
  if (Array.isArray(lcd)) {
    if (lcd.length == 0) {
      return null;
    }
    lcd = lcd[0];
  }
  if (["wy", "bo_wy", "bo_latn"].includes(lcd)) {
    return "Wylie";
  }
  if (!isLangCode(lcd)) {
    return lcd;
  }
  return Iso639M.getLangNameFromCode(lcd);
}

export function listChunk(arr, len) {
  var chunks = [],
    i = 0,
    n = arr.length;

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)));
  }

  return chunks;
}

export function capitalize(mystr, force = false) {
  if (typeof(mystr) === "string" && mystr.length > 0) {
    const firstchar = mystr.charAt(0).toUpperCase();
    const reststr = force ? mystr.substring(1).toLowerCase() : mystr.substring(1);
    return firstchar + reststr;
  }
  return mystr;
}

/**
 * Takes a solr facet_fields list and processes it into a dictionary of facets
 * @param fdata
 * @returns {{}}
 */
export function processFacets(fdata) {
  let ffdata = fdata.facet_fields;
  let facets = {};
  let fctdata;
  Object.keys(ffdata).forEach((fname, fni) => {
    fctdata = {};
    for (let i = 0; i < ffdata[fname].length; i += 2) {
      let fkey = ffdata[fname][i];
      let fval = ffdata[fname][i + 1];
      fctdata[fkey] = fval;
    }
    facets[fname] = fctdata;
  });
  return facets;
}

export function getNow() {
  const dt = new Date();
  return dt.getTime() + dt.getMilliseconds();
}

export function getPreviousInList(item, list, loop=false) {
  const iind = list.indexOf(item);
  if (iind > 0) {
    return list[iind - 1];
  }
  if (loop && iind === 0) {
    return list[list.length - 1];
  }
  return false;
}


export function getNextInList(item, list, loop=false) {
  const iind = list.indexOf(item);
  if (iind < list.length - 1) {
    return list[iind + 1];
  }
  if (loop && iind > 0) {
    return list[0];
  }
  return false;
}
