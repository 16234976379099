import create from "zustand";
import {
  nextPage,
  pageInRange,
  parsePagination,
  prevPage,
  zfill,
} from "../catalog/catalogUtils";
import axios from "axios";
import jsonpAdapter from "../common/axios-jsonp";

/**
 * Zustand Store that stores the currently loaded bibl or dox record. Loaded in CatalogFrame
 *
 * @type {UseStore<{
 *         removeEdBibl: function(): *,
 *         setDoc: function(*): *,
 *         setEdBibl: function(*): *,
 *         setToc: function(*): *,
 *         edbibl: null,
 *         doc: null,
 *         toc: string,
 *         reset: function(): *,
 *         removeDoc: function(): *
 *       }>}
 */
export const useBiblStore = create((set, get) => ({
  doc: null, // the bibl solr doc
  docToc: null,
  dynamicSections: null,
  edBibl: null, // the edition's bibl solr doc
  toc: "dox", // the type of TOC showing (dox or vol)
  collname: '', // Collection name
  setColl: async (csig) => {
    const collnm = await getInfo('coll', csig);
    set({collname: collnm });
  },
  setEdBibl: (newedbib) => set((state) => ({ edBibl: newedbib })),
  removeEdBibl: () => set({ edBibl: null }),
  setDoc: (newdoc) => {
    // console.log("new doc in useBiblStore", newdoc);
    if (newdoc?.sections?.length > 0) {
      // Add a toc property to the bibl object that has only first level sections in order
      newdoc.toc = newdoc.sections.filter((s) => { return s?.level_i === 1; });
      newdoc.toc = newdoc.toc.map((s) => { return s?.chapid_s; });
    }
    set((state) => ({ doc: newdoc }));
  },
  removeDoc: () => set({ doc: null }),
  setDynamicSections: (ds, toc) => set({docToc: toc, dynamicSections: ds}),
  reset: () => set({ doc: null, edBibl: null, toc: "dox" }),
  setToc: (newtoc) => set((state) => ({ toc: newtoc })),
}));

/**
 * An async function to query solr for different information
 *
 * @param csig
 * @returns {Promise<void>}
 */
async function getInfo(itype, bibid) {
  let request = {
    url: process.env.REACT_APP_SOLR_URL,
    adapter: jsonpAdapter,
    dataType: 'jsonp',
    callbackParamName: "json.wrf",
    headers: {
      'Content-Type': null
    },
  };
  switch(itype) {
    case "coll":
      request['params'] = {
        q: `coll:${bibid}`,
        fq: ['type:tibbibl', 'subtype:collection'],
        fl: 'title_en',
      };
      break;
    case "sections":
      request['params'] = {
        q: `bibid_s:${bibid}`,
        fq: ['type:textsection'],
        fl: 'id, section_num_s, section_title_s',
        rows: 1000,
        sort: 'section_num_s asc'
      };
      break;
    case "chapters":
      request['params'] = {
        q: `bibid_s:${bibid}`,
        fq: ['type:chapter'],
        fl: '*',
        rows: 1000,
        sort: 'chapid_s asc'
      };
      break;
    case "dynamic":
      request['params'] = {
        q:`bibid_s:${bibid}`,
        fq:['type:textsection'],
        fl: 'id, section_level_i',
        sort: 'section_num_s asc',
        rows: 1000
      };
  }
  const { data } = await axios.request(request).catch(function (e) {
    console.error("error in call collection name", e);
  });
  // console.log("data returned", data);
  try {
    switch (itype) {
      case "coll":
        return data?.response?.docs[0]?.title_en[0];
      case "sections":
        return data?.response?.docs
    }
  } catch (e) {
    console.log(`error in call: ${itype} : ${bibid}`);
  }
  return false;
}
