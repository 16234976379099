import React, { useEffect, useState } from "react";
import { useSolr } from "../hooks/useSolr";
import THLSkeleton from "../common/THLSkeleton";
import "./css/catalog.scss";
import {
  GoTriangleDown,
  GoTriangleRight,
  GoPrimitiveSquare,
} from "react-icons/all";
import { Link } from "react-router-dom";
import {
  buildSigla,
  getDoxRange,
  getDoxTitleSimple,
  getMainTitle,
  makeRanges,
  zfill,
} from "./catalogUtils";
import { useBiblStore } from "../hooks/useBiblStore";
import $ from "jquery";

/**
 * DoxToc : initializes the TOC by getting the first level doxcats and displaying as an toggle-able list
 *
 * @param bibl
 * @param title
 * @param isOpen
 * @returns {JSX.Element}
 * @constructor
 */
export default function DoxToc({ biblp, selId, openFirst, noneCallback }) {
  const bibl = useBiblStore((state) => state.doc);
  const level = 1;

  let query = `type:doxcat AND coll:${bibl?.coll} AND edsig:${bibl?.edsig} AND level_i:${level}`;

  const dox_toc_params = {
    q: query,
    rows: 1000,
    fl: "*,[child limit=1000]",
    sort: "pos_i asc",
    wt: "json",
  };

  const {
    isLoading: isDoxCatLoading,
    data: doxCats,
    isError: isDoxCatError,
    error: doxCatError,
  } = useSolr(dox_toc_params);

  // UseEffect to scroll first selected element into view in Dox TOC
  useEffect(() => {
      setTimeout(() => {
        let selel = $(".c-toc.dox .toc-text.selected");
        if (selel.length === 0) {
          selel = $(".c-toc.dox .c-toc-doxcat.selected");
        }
        selel = selel.get(0);
        if (selel) {
          selel.scrollIntoView({block: "center", inline: "nearest"});
        }
      }, 1500);
  }, [bibl]);

  if (isDoxCatLoading) {
    return <THLSkeleton />;
  }
  if (isDoxCatError) {
    console.log("Solr Loading Error:", doxCatError);
    return <p>There was a problem.</p>;
  }

  let docs = doxCats && "docs" in doxCats ? doxCats["docs"] : false;
  if (bibl && (!docs || docs?.length === 0)) {
    setTimeout( function () {
      noneCallback();
    }, 600);
    return null;
  }

  // console.log('doxcats in dox toc', doxCats);
  docs.sort(siblingOrder);

  return (
    <ul>
      {docs &&
        docs.map((dcat, n) => {
          return (
            <li key={`cattoc-dox-${n}`}>
              <DoxTocLink doc={dcat} selId={selId} />
            </li>
          );
        })}
    </ul>
  );
}

/**
 * DoxTocLink creates a tree node in the Dox Toc that is not a link to a text
 * This can be a doxographic category or a volume within a doxographic category.
 * For a straight volume TOC that just lists volumes and texts see VolToc.js
 *
 * @param doc
 * @param isOpen
 * @param selId
 * @returns {JSX.Element|null}
 * @constructor
 */
function DoxTocLink({ doc, selId = "" }) {
  const bibl = useBiblStore((state) => state.doc);
  const [isOpen, setOpen] = useState(false);
  const myid = doc?.id;
  const isMissing = doc?.status_s === "missing";


  // Update state based on dox doc and current bibl (dox or text) showing
  useEffect(() => {
    let newState = isOpen;
    // Reasons to set open to true:
    // If the current bibl id is the dox
    if (myid === bibl?.id) {
      newState = true;
    }
    // if the current dox id is in the list of child dox ids of an ancestory
    if (Array.isArray(bibl?.toc_ids_ss) && bibl.toc_ids_ss.includes(doc?.id)) {
      newState = true;
    }
    // If the current text number is between the range of the current dox cat
    if (doc?.st_text_i && doc?.end_text_i && bibl?.text_num) {
      // console.log(doc.st_text_i, doc.end_text_i, bibl.text_num);
      if (doc.st_text_i <= bibl.text_num && bibl.text_num <= doc.end_text_i) {
        newState = true;
      }
    }
    // If the text is in the list of current doxcat's texts
    if (
      bibl?.text_num &&
      Array.isArray(doc?.texts_ss) &&
      doc?.texts_ss.includes(zfill(bibl?.text_num, 4))
    ) {
      newState = true;
    }
    setOpen(newState);
    // console.log("bibl change!", isOpen, doc, bibl);
  }, [bibl]);

  let icon = isOpen ? (
    <GoTriangleDown className="toggle-icon down" />
  ) : (
    <GoTriangleRight className="toggle-icon right" />
  );

  if (isMissing) {
    icon = <GoPrimitiveSquare />;
  }

  let children = doc?.children_ss?.map((cid, cn) => {
    return <DoxTocChild bibl={bibl} cid={cid} key={cid} />;
  });

  let texts = doc?.texts_ss;

  // unclassified is true if there are unclassified texts and the class has subclassifications
  const unclassified = texts?.length > 0 && doc?.children_ss?.length > 0;

  const toggleme = () => {
    setOpen(!isOpen);
  };

  if (!doc) {
    return null;
  }
  let selClass = "";
  let doc_title = getDoxTitleSimple(doc);
  let myrange = getDoxRange(doc);
  if (doc?.texts_num_i) {
    myrange += `; ${doc.texts_num_i} texts`;
  }

  // If volume or a text in it is being shown, set as selected
  const selected =
    myid === bibl.id || doc?.texts_ss?.includes(zfill(bibl.text_num, "4"))
      ? " selected"
      : "";
  if (isMissing) {
    return null;
  }

  return (
    <div id={myid} className={`c-toc-doxcat lvl-${doc?.level_i}${selected}`}>
      <div className="dox-label">
        <span className="toc-toggle" onClick={toggleme}>
          {icon}
        </span>
        <Link
          to={`/catalog/${doc.coll}/${doc.edsig}/dox/${doc.id}`}
          className={`toggle-link${selClass}`}
        >
          {doc?.path_id_s}: {doc_title} <span className="toc-dox-range">{myrange}</span>
        </Link>
      </div>
      {isOpen && !unclassified && (
          <ul className="textlist ml-0">{doc?.texts_ss?.map((tnum, tn) => {
            if (tnum.match(/[\d-]+:\S+-dox/)) {
              const [tnums, doxid] = tnum.split(':');
              const mid = `${doc?.coll}-${doc?.ed}-${tnums}`;
              const label = (tnums.includes('-')) ? 'texts' : 'text';
              const doxlabel = doxid.split('-').slice(1, -1).join('.');
              return <li id={mid} key={mid}><i>For {label} {tnums}, see section {doxlabel} below</i></li>
            }
            return (
                <DoxTocText bibl={bibl} tnum={tnum} key={`${doc.id}-text-${tn}`} />
            );
          })}</ul>
      )}
      {isOpen && children}
      {isOpen && unclassified && (
          <DoxTocTextGroup doc={doc} texts={texts} />
      )}
    </div>
  );
}

function DoxTocChild({ cid }) {
  // console.log(cid);
  // const bibl = useBiblStore((state) => state.doc);
  const dox_params = {
    q: `type:doxcat AND id:${cid}`,
    fl: "*",
    wt: "json",
  };
  const {
    isLoading: isDoxChildLoading,
    data: doxChild,
    isError: isDoxChildError,
    error: doxChildError,
  } = useSolr(dox_params);

  if (isDoxChildLoading || doxChild.numFound === 0) {
    return null;
  }
  if (isDoxChildError) {
    console.log("Solr Loading Error:", doxChildError);
    return <p>There was a problem.</p>;
  }
  const doxdoc = doxChild?.docs[0];
  return <DoxTocLink doc={doxdoc} />;
}

/**
 * Copied from DoxTocLink, this component groups texts together in an Unclassified subcategory when
 * there are other subcategories so that all subcategories can be seen at once.
 *
 * @param texts
 * @constructor
 */
function DoxTocTextGroup({ doc, texts }) {
  const bibl = useBiblStore((state) => state.doc);
  const [isOpen, setOpen] = useState(false);
  // console.log("text group", texts);
  // Update state based on dox doc and current bibl (dox or text) showing
  useEffect(() => {
    //let newState = false; // Keep closed by default. TODO: will have to add code to sniff out when selected text is in list
    // setOpen(newState);
    // console.log("bibl change!", isOpen, doc, bibl);
    const tnum = zfill(bibl.text_num, 4);
    setOpen(texts?.includes(tnum));
  }, [bibl]);

  const toggleme = () => {
    setOpen(!isOpen);
  };

  let icon = isOpen ? (
      <GoTriangleDown className="toggle-icon down" />
  ) : (
      <GoTriangleRight className="toggle-icon right" />
  );

  const grouplvl = (typeof(doc?.level_i) === 'number') ? doc?.level_i * 1 + 1 : 0;

  const numlist = texts.map((tstr, i) => {
    return tstr * 1;
  });
  const numranges = makeRanges(numlist);

  const sigpref = buildSigla(doc?.coll, doc?.edsig)

  return (
      <div id={`${doc?.id}-texts`} className={`c-toc-doxcat lvl-${grouplvl}`}>
          <span className="toc-toggle" onClick={toggleme}>
            {icon}
          </span>
          <Link
              to={`/catalog/${doc.coll}/${doc.edsig}/dox/${doc.id}`}
              className={`toggle-link`}
          >
            <span title="Texts in this group have no subgenre classification">Unassigned ({sigpref}{numranges.join(', ')})</span>
          </Link>
          {isOpen && (
              <ul className="textlist ml-0">{texts?.map((tnum, tn) => {
                    if (tnum.match(/[\d-]+:\S+-dox/)) {
                      const [tnums, doxid] = tnum.split(':');
                      const mid = `${doc?.coll}-${doc?.ed}-${tnums}`;
                      const label = (tnums.includes('-')) ? 'texts' : 'text';
                      const doxlabel = doxid.split('-').slice(1, -1).join('.');
                      return <li id={mid} key={mid}><i>For {label} {tnums}, see section {doxlabel} below</i></li>
                    }
                    return (
                        <DoxTocText bibl={bibl} tnum={tnum} key={`${doc.id}-text-${tn}`} />
                    );
                  })}
              </ul>
          )}
      </div>
  );

}

function DoxTocText({ tnum }) {
  const bibl = useBiblStore((state) => state.doc);
  const dox_text_params = {
    q: `type:tibbibl AND subtype:text AND coll:${bibl?.coll} AND edsig:${
      bibl?.edsig
    } AND text_num:${tnum * 1}`,
    fl: "*,[child limit=1000]",
    wt: "json",
  };
  /*
  if (tnum === "0030") {
    console.log("text 30 query", dox_text_params);
  }*/

  const {
    isLoading: isTextLoading,
    data: textResults,
    isError: isTextError,
    error: textError,
  } = useSolr(dox_text_params);

  if (isTextLoading) {
    return null;
  }

  if (isTextError) {
    console.log("Text List Loading Error", textError);
    return (
      <p>
        There was a problem loading text list: {bibl.coll} {bibl.edsig}{" "}
      </p>
    );
  }

  if (textResults?.numFound === 0) {
    return (
      <li key={`dox-toc-nf-${tnum}`}>
        <span>{tnum}: No text found </span>
      </li>
    );
  }
  const tdoc = textResults?.numFound > 0 ? textResults.docs[0] : [];
  const dtocid = `dox-toc-${tdoc.id}`;
  const selected = bibl?.id === tdoc.id ? " selected" : "";
  return (
    <li key={dtocid} id={dtocid} className={`toc-text${selected}`}>
      <DoxTextTocLink bibl={bibl} tdoc={tdoc} vol={tdoc.vol_num} />
    </li>
  );
}

export function DoxTextTocLink({ tdoc }) {
  const displayId = tdoc?.display_id || tdoc?.id || "no id found";
  const mytitle = getMainTitle(tdoc);
  const mainTitle = <span className={mytitle?.lang}>{mytitle?.title}</span>;
  // console.log("text doc", tdoc);
  return (
    <Link to={`/catalog/${tdoc.coll}/${tdoc.edsig}/${tdoc.text_num}`}>
      {displayId}: {mainTitle}{" "}
    </Link>
  );
}

function siblingOrder(a, b) {
  const anum = a.pos_i * 1;
  const bnum = b.pos_i * 1;
  if (anum > bnum) {
    return 1;
  }
  if (bnum > anum) {
    return -1;
  }
  return 0;
}
