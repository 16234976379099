import { useCatalog } from "../hooks/useCatalog";
import { useInfinitePage } from "../hooks/useInfinitePage";
import React, { useEffect, useRef, useState } from "react";
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import {getBiblLang, getEdResource, zfill} from "../catalog/catalogUtils";
import $ from "jquery";
import { usePager } from "../hooks/usePager";
import { useBiblStore } from "../hooks/useBiblStore";
import {useSearchStore} from "../hooks/useSearchStore";
import {useQueryClient} from "react-query";
import {Redirect, useParams} from "react-router-dom";
import {TextReaderHeader} from "./TextReaderHeader";

/**
 * TextReaderPages : for representing an unstructured digital texts with milestones from a single edition.
 *
 * @returns {JSX.Element}
 * @constructor
 */
export function TextReaderPages() {
  const bibl = useBiblStore((state) => state.doc);
  const edBibl = useBiblStore((state) => state.edBibl);
  const pager = usePager();
  const pgpatt = edBibl?.resource_txtpg_id_s;
  const sdval = edBibl.has_sides ? pager?.side : "";
  const search = useSearchStore();
  const qryClient = useQueryClient();
  const params = useParams();

  const pgid = pgpatt
    ?.replace("__VOL__", pager?.vol)
    .replace("__PAGE__", pager?.page)
    .replace("__SIDE__", sdval)
    .replace("__TXT__", zfill(bibl?.text_num, 4));

  /*
  console.log("pgpatt: " + pgpatt, bibl);
  console.log("page id: " + pgid);
  */

  const {
    data,
    error,
    isFetching,
    isFetchingPreviousPage,
    isFetchingNextPage,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    status,
  } = useInfinitePage(pgid);

  const loadMoreButtonRef = useRef();

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
    threshold: 0.1,
  });

  useEffect(() => {
    // Set usePager.loaded to a list of page keys. For later scrolling, to determine if already loaded
    pager.setLoaded(
      data?.pages?.map((pg, pgi) => {
        return pg?.page?.key;
      })
    );
    // Make sure at least 6 pages are loaded
    if (data?.pages?.length < 6) {
      if (hasPreviousPage) {
        fetchPreviousPage();
      }
      if (hasNextPage) {
        fetchNextPage();
      }
    }
  }, [data]);

  useEffect(() => {
    data?.pages?.map((pg, pgi) => {
      if (pg?.page?.key) {
        qryClient.invalidateQueries(["digpage", pg.page.key]);
      }
    });
  }, [search?.isActive]);

  if (status === "loading") {
    return <p>Loading...</p>;
  }
  if (status === "error") {
    console.log("error loading Text Reader Page: " + pgid, error)
    return <p>Oops! Something went wrong loading Text Reader Pages!</p>;
  }
  // console.log("bibl in pager", bibl);
  // console.log("infinite page data", data);
  if (!bibl?.hastext) {
    return (
      <div id="text-reader-pages" className="c-text-view">
        <p>There is no digital text for this work.</p>
      </div>
    );
  }

  if (bibl?.texttype === 'styled') {
    const textpath = `/catalog/${params.coll}/${params.ed}/${params.txtnum}/text`;
    return <Redirect to={textpath} />;
  }
  const mylang = getBiblLang(bibl);

  const pages = (data?.pages?.length === 1 && !data.pages[0]) ? <p className="font-italic smaller">No pages found</p> :
      data.pages.map((pg, pid) => {
        // console.log("page in map", pg.page.key);
        return (
            <TextReaderPage
                key={`text-digital-page-${pid}`}
                page={pg}
                getPrevious={fetchPreviousPage}
            />
        );
      });
  return (
    <div id="text-reader-pages" className="c-text-view">
      <div className={mylang}>
        {pages}
      </div>
      <div className="infinite-load-btn">
        <button
          ref={loadMoreButtonRef}
          className={isFetchingNextPage
              ? "loading"
              : hasNextPage
                  ? "canload"
                  : "finis"}
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
        >
          {isFetchingNextPage
            ? "Loading more..."
            : hasNextPage
            ? "Load More"
            : <span className="hidden">End of Pages</span> }
        </button>
      </div>
      <div>{isFetching && !isFetchingNextPage ? "Fetching..." : null}</div>
    </div>
  );
}

function TextReaderPage({ page, getPrevious }) {
  const search = useSearchStore();
  const updateVisible = usePager((state) => state.updateVisible);
  if (!page) {
    page = { key: "pgnf", page: "" };
  }
  // const visible = catalog.visible;
  const pageRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const pgid = `c-text-page-${page.page.key}`;

  const observerCallback = (entries, observer) => {
    entries.forEach((entry) => {
      setIsVisible(entry.isIntersecting);
    });
  };

  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.3,
  };

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, options);
    if (pageRef.current) observer.observe(pageRef.current);
    return () => {
      if (pageRef.current) observer.unobserve(pageRef.current);
    };
  }, [pageRef, options]);

  useEffect(() => {
    const action = isVisible ? "add" : "remove";
    updateVisible(action, page.page.key);
  }, [isVisible]);

  useEffect( () => {
    if (search?.isActive) {
      const qry = search.getQuery();
    }
  }, [search?.isActive, search?.isNew]);


  return (
    <div
      id={pgid}
      className={`c-text-page`}
      data-id={page.page.key}
      ref={pageRef}
    >
      {page.page}
    </div>
  );
}
