import React, { useEffect, useRef, useState } from "react";
import "./TextReader.scss";
import { TextReaderHeader } from "./TextReaderHeader";
import { TextReaderPages } from "./TextReaderPages";
import { TextReaderScans } from "./TextReaderScans";
import { usePager } from "../hooks/usePager";
import { useBiblStore } from "../hooks/useBiblStore";
import {TextReaderText} from "./TextReaderText";
import {useSolr} from "../hooks/useSolr";
import {useParams} from "react-router-dom";

export default function TextReader({viewMode, vol, page, sid, dragged}) {
  const bibl = useBiblStore((state) => state.doc);
  const edBibl = useBiblStore((state) => state.edBibl);
  const setPage = usePager((state) => state.setPage);
  const setSection = usePager((state) => state.setSection);
  const setRange = usePager((state) => state.setRange);
  const params = useParams();

  const [view, setView] = useState("text"); // text = view digital text, scans = view page scans

  const query = {
    q: `bibid_s:${bibl?.id} AND type:textpg`,
    fl: "id,pnum_i",
    stats: true,
    'stats.field': "pnum_i",
  };
  const {
    isLoading: areStatsLoading,
    data: sdata,
    isError: isStatError,
    error: statError,
  } = useSolr(query);

  useEffect(() => {
    if (viewMode?.length > 0) {
      setView(viewMode);
    }

    // Initialize
    if (['pages', 'text'].includes(view) && vol && page) {
      const mtch = /(\d+)([ab])/.exec(page)
      const pg = (mtch) ? mtch[1] : page;
      const side = (mtch) ? mtch[2] : false;
      setPage(vol, pg, side);
    } else if (bibl?.vol_start_i) {
      if (edBibl?.pagination_type === 'digpage') {
        setPage(bibl?.vol_start_i, 1, bibl?.pg_start_side_s);
      } else {
        setPage(bibl?.vol_start_i, bibl?.pg_start_i, bibl?.pg_start_side_s);
      }
    }
  }, [viewMode, vol, page, bibl]);

  useEffect(() => {
    if (sid) {
      sid = sid.replace(/^1-/, 'a').replace(/^2-/, 'b').replace(/^3-/, 'c');
      setSection(sid);
    }
  }, [sid]);

  return (
    <div id="c-text-reader" className={view}>
      <TextReaderHeader view={view} setView={setView} />
      {view === "scans" && <TextReaderScans dragged={dragged} />}
      {view === "pages" && <TextReaderPages />}
      {view === "text" && <TextReaderText />}
    </div>
  );
}
