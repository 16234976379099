import { useSolr } from "./useSolr";
import React from "react";

/**
 * useBibl : A hook that implements useSolr (implementing useQuery) to retrieve a Bibl's solr doc based on its ID
 *
 * @param biblId
 * @param byPass
 * @returns {{isLoading: boolean, isError: boolean, data: *, error: unknown}}
 */
export function useBibl(biblId = "", byPass = false) {
  const query = {
    q: `id:${biblId}`,
    fl: `*,[child limit=5000]`,
  };
  const {
    isLoading: isSolrLoading,
    data: solrRes,
    isError: isSolrError,
    error: solrError,
  } = useSolr(query, byPass);

  let newData = solrRes;
  if (!isSolrLoading && !isSolrError) {
    newData = solrRes.numFound > 0 ? solrRes.docs[0] : false;
  }

  return {
    isLoading: isSolrLoading,
    data: newData,
    isError: isSolrError,
    error: solrError,
  };
}
