import {
  getBiblLang,
  getFieldsByString,
  getLangField,
  getLangTypedField,
  getNote,
  solrFieldValue,
} from "../catalogUtils";
import { langcodeFromField } from "../../common/utils";
import React from "react";
import CatalogPopover from "../../common/CatalogPopover";

/**
 * The Physical Description section of a Bibl record
 * Physical Facets are not hard coded but are display only if fields are there.
 * Field names such as ["pfacet_illustrations_s", "pfacet_script_tib"] get labelled as "Illustrations" and "Script"
 * by parsing those field names.
 *
 * TODO: Need to link the pagination to the page reader once it is implemented
 *
 * @param bibl
 * @returns {JSX.Element}
 * @constructor
 */
export function BiblPhysDesc({ bibl }) {
  const btype = bibl["subtype"];
  return (
    <div className="c-bibl_physdesc">
      <h2>Physical Description</h2>
      <ul className="list-unstyled">
        {btype === "edition" && <BiblPhysCountsEd bibl={bibl} />}
        {btype !== "edition" && (
          <>
            <BiblPhysVol bibl={bibl} />
            <BiblPhysPage bibl={bibl} />
            <BiblPhysSides bibl={bibl} />
            {btype === "text" && <BiblPhysSections bibl={bibl} />}
          </>
        )}
        <BiblPhysFacets bibl={bibl} />
      </ul>
    </div>
  );
}

function BiblPhysVol({ bibl }) {
  // console.log("bibl for vol", bibl);
  const bibtype = bibl["subtype"];
  const ised = bibtype === "edition";
  const isvol = bibtype === "volume";
  const istext = bibtype === "text";
  const ismulti = (istext && bibl['vol_start_i'] !== bibl['vol_end_i'])
  // const bibllang = getBiblLang(bibl);
  let volstring = istext ? getLangTypedField(bibl, "vol", "s") : false;
  if (volstring) {
    volstring = (
      <span>
        {" ("}
        {volstring}
        {")"}
      </span>
    );
  }
  // Volume vs. Text Vol Info
  let txttotal = null;
  if (isvol) {
    txttotal = bibl["texts_total_s"] === "0" ? "< 1" : bibl["texts_total_s"];
  }

  let txtvoldisplay = <span>{bibl["vol_num"]} {volstring}, text {bibl["vol_txtnum_i"]}</span>;

  if (ismulti) {
    // Some multi text pagination includes the volume with start and end as kt.d.0003: '5:v5:21a.1-v5:292a.7'
    // Some do not as kt.d.0001: `1:1b.1-311a.6`. The code below handles both.
    txtvoldisplay = <ul className="mb-2">
      {bibl.vol_pg_ss.map((vln, vli) => {
        const vlnpts = vln.split(/[:-]/); // split on colon or dash
        const vnum = vlnpts.shift();      // pop the volume num off the top (first item)
        const trimpts = vlnpts.filter((it, n) => { return it.trim() !== 'v' + vnum});  // strip any other 'v' + vnum
        return <li key={[vnum, vli]}>Volume {vnum}: {trimpts.join('-')} </li>   // join with dash in list item element
      })}
    </ul>
  }

  return (
    <>
      <li key="bibl-id-vol" className="volid">
        {!ised && (
          <>
            <label>Volume</label>
            {txtvoldisplay}
          </>
        )}
      </li>
      {isvol && (
        <>
          <li key="bibl-id-texts-in-vol">
            <label>Texts in Volume</label>
            <span>{txttotal}</span>
          </li>

          <li key="bibl-id-first-vol-text">
            <label>First Text</label>
            <span>{bibl["texts_first_s"]}</span>
          </li>

          <li key="bibl-id-last-vol-text">
            <label>Last Text</label>
            <span>{bibl["texts_last_s"]}</span>
          </li>
        </>
      )}
    </>
  );
}

function BiblPhysPage({ bibl }) {
  if (!bibl) {
    return null;
  }
  const btype = bibl["subtype"];
  const ismulti = (btype === "text" && bibl['vol_start_i'] !== bibl['vol_end_i'])
  let stpage =
    "pg_start_text_s" in bibl
      ? bibl["pg_start_text_s"]
      : "pg_start_s" in bibl
      ? bibl["pg_start_s"]
      : "n/a";
  let endpg =
    "pg_end_text_s" in bibl
      ? bibl["pg_end_text_s"]
      : "pg_end_s" in bibl
      ? bibl["pg_end_s"]
      : "n/a";
  if (ismulti) {
    stpage = `Volume ${bibl['vol_start_i']}: ${stpage}`;
    endpg = `Volume ${bibl['vol_end_i']}: ${endpg}`;
  }
  return (
    <li key="bibl-phys-page">
      <label>Pagination</label>
      <span>
        {stpage}-{endpg}
      </span>
    </li>
  );
}

function BiblPhysSides({ bibl }) {
  return (
    <li key="bibl-phys-sides">
      <label>Total Sides</label>
      <span>{bibl["sides_total_s"]}</span>
    </li>
  );
}

function BiblPhysSections({ bibl }) {
  if (typeof bibl["cle_total_s"] === "undefined") {
    return null;
  }
  return (
    <>
      <li key="bibl-phys-sections">
        <label>Number of Sections</label>
        <span>{bibl["cle_total_s"]}</span>
        <ul>
          <li key="bibl-phys-sections-front">
            <label>Front</label>
            <span>{bibl["cle_front_s"]}</span>
          </li>
          <li key="bibl-phys-sections-body">
            <label>Body</label>
            <span>{bibl["cle_body_s"]}</span>
          </li>
          <li key="bibl-phys-sections-back">
            <label>Back</label>
            <span>{bibl["cle_back_s"]}</span>
          </li>
        </ul>
      </li>
      <li key="bibl-phys-section-types">
        <label>Section Types</label>
        {getLangField(bibl, "cle_name")}
      </li>
    </>
  );
}

function BiblPhysFacets({ bibl }) {
  if (!bibl || !("pfacets" in bibl)) {
    return null;
  }
  let pffields = Array.isArray(bibl["pfacets"])
    ? bibl["pfacets"]
    : [bibl["pfacets"]];
  const marginpf = pffields?.filter((pff, pfn) => {
    return pff["subtype"] === "margin";
  });
  const otherpf = pffields?.filter((pff, pfn) => {
    return pff["subtype"] !== "margin";
  });
  const pffelements = otherpf?.map((pf, ky) => {
    return <BiblPhysFacet key={`physfacet-${ky}`} data={pf} />;
  });
  return (
    <>
      <BiblMarginFacet margins={marginpf} />
      {pffelements}
    </>
  );
}

function BiblPhysFacet({ data }) {
  const mylabel = data.subtype.replace(/\-/g, " ");
  const lang = data.lang[0];
  const lang_class = lang === "t" ? "" : lang;
  const myval = data[`content_${lang}`];
  const note = getNote(data);
  if (myval) {
    return (
      <li key={data.id}>
        <label className="text-capitalize">{mylabel}</label>
        <span className={lang_class}>{myval}</span>
        {note && <CatalogPopover title={`Note on ${mylabel}`} content={note} />}
      </li>
    );
  }
}

function BiblMarginFacet({ margins }) {
  const marginels = margins.map((mg, mgi) => {
    const mlang = mg["lang"];
    return (
      <li key={`physfacet-margin-${mgi}`}>
        <label>{mg["pfsubtype_s"]}</label>
        <span className={mlang}>{mg[`content_${mlang}`]}</span>
      </li>
    );
  });
  return marginels;
}

function BiblPhysCountsEd({ bibl }) {
  const count_fields = getFieldsByString(bibl, "count_");
  let countfields = [];
  for (let n = 0; n < count_fields.length; n++) {
    const fldnm = count_fields[n];
    const ctype = fldnm.includes("_") ? fldnm.split("_")[1] : fldnm;
    const biblfielditems = Array.isArray(bibl[fldnm]) ? bibl[fldnm] : [bibl[fldnm]];
    for (let i = 0; i < biblfielditems.length; i++) {
      const ctvals = biblfielditems[i].split("|");
      countfields.push(
        <li key={`ed-count-${ctype}-${i}`}>
          <label className="text-capitalize">{`${ctvals[0].replace(
            "*",
            ""
          )} ${ctype}`}</label>
          <span>{ctvals[1]}</span>
        </li>
      );
    }
  }
  return countfields;
}
