import {useSearchStore} from "../hooks/useSearchStore";
import {useSolr} from "../hooks/useSolr";

/**
 * Shows the parenthetical hit types after the item label in the search results list
 *
 * @param bibid
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function HitsSummary({ bibid }) {
    const search = useSearchStore();
    const qobj = {
        q: search?.solrQuery?.q,
        fq: `bibid_s:${bibid}`,
        facet: true,
        'facet.field': 'type',
        'facet.mincount': 1,
    };
    const {
        isLoading: isHitsLoading,
        data: hitsearch,
        isError: isHitsError,
        error: hitsError,
    } = useSolr(qobj);
    //console.log(bibid, search)
    if (isHitsError || isHitsLoading) {
        return null;
    }
    // console.log(hitsearch);
    const hittypes = Object.keys(hitsearch?.facets?.type).map((ky, kyi) => {
        switch(ky) {
            case 'textpg':
            case'textsection':
                return 'text';
            case 'voltoc':
                return "table of contents";
            default:
                return ky;
        }
    });
    const hitsum = hittypes.join(', ');
    return <>{hitsum}</>;
}
