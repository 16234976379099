import React, { useEffect, useRef, useState } from "react";
import { usePager } from "../hooks/usePager";
import {
  getPagePath,
  nextPage,
  pageStrToObj,
} from "../catalog/catalogUtils";
import ReactImageMagnify from "react-image-magnify";
import { useBiblStore } from "../hooks/useBiblStore";

export function TextReaderScans(props) {
  const bibl = useBiblStore((state) => state.doc);
  const pager = usePager();
  const dragged = props?.dragged;


  // TODO: Use pager.visible to update the Pager UI with the currently showing page

  const [pglist, setPageList] = useState([
    `v${pager?.vol}p${pager?.page}${pager?.side}`,
  ]);

  const [lastVisible, setLastVisible] = useState();

  const updateLastVisible = (lv) => {
    setLastVisible(lv);
    pager.updateVisible("add", lv);
  };

  // TODO: set a max number of loaded pages and remove from top as scrolling down

  // TODO: implement scrolling up for pages

  // Load 5 pages initially
  useEffect(() => {
    for (let n = 0; n < 4; n++) {
      const lastpage = pglist[pglist.length - 1];
      const pgobj = pageStrToObj(lastpage);
      [pgobj.page, pgobj.side] = nextPage(pgobj.page, pgobj.side);
      pglist.push(`v${pgobj.vol}p${pgobj.page}${pgobj.side}`);
    }
    setPageList(pglist);
  }, []);

  // Load another page once the last one becomes visible
  useEffect(() => {
    if (!lastVisible) {
      return;
    }
    const pgobj = pageStrToObj(lastVisible);
    [pgobj.page, pgobj.side] = nextPage(pgobj.page, pgobj.side);
    const pgstr = `v${pgobj.vol}p${pgobj.page}${pgobj.side}`;
    if (!pglist.includes(pgstr)) {
      pglist.push(pgstr);
      setPageList(pglist);
    }
  }, [lastVisible]);

  let pgscans = pglist.map((pg, pgi) => {
    return (
      <TextScan
        key={`page-scan-${pg}`}
        page={pg}
        setVisible={updateLastVisible}
        dragged={dragged}
      />
    );
  });
  // console.log("bibl", bibl);
  if (!bibl?.hasscans) {
    pgscans = <p>There are no scans for this text</p>;
  }
  return (
    <div id="c-text-page-scans" className="c-text-view">
      {pgscans}
    </div>
  );
}

/**
 * An individual page scan. Uses ref with observerCallback to determine if visible and when visible,
 * calls the load next page function, which adds the next page to the list of displayed pages.
 * @param page
 * @param getNext
 * @returns {JSX.Element|null}
 * @constructor
 */
function TextScan({ page, setVisible, dragged }) {
  // const getPagePath = useCatalog((state) => state.getPageImagePath);
  const edBibl = useBiblStore((state) => state.edBibl);
  const updateVisible = usePager((state) => state.updateVisible);
  const pageRef = useRef(null);

  // TODO: Fix implementation of zoom or find another package. Currently zoom if off centered if div expanded

  const observerCallback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setVisible(page); // AddPage adds the page list
      } else {
        updateVisible("remove", page);
      }
    });
  };

  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.3,
  };

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, options);
    if (pageRef.current) observer.observe(pageRef.current);
    return () => {
      if (pageRef.current) observer.unobserve(pageRef.current);
    };
  }, [pageRef, options]);

  /*
  useEffect(() => {
    // TODO: Figure out how to use this to refresh the Zoom component so the zoom looks proper
    console.log("dragged = ", dragged);
  }, [dragged]);
   */

  const pgobj = pageStrToObj(page);
  if (!pgobj) {
    console.warn("No page data in Text Scan component");
    return null;
  }

  const pgurl = getPagePath(edBibl, pgobj);
  const pginfo = (
    <>
      Volume {pgobj.vol}, Page {pgobj.page}
      {pgobj.side}
      <a href={pgurl} target="_blank">
        {" "}
        <span className="icon shanticon-link-external"> </span>
      </a>
    </>
  );
  return (
    <div className="c-text-scan" ref={pageRef}>
      <div className="img-wrapper">
        <ZoomScan pgurl={pgurl} />
      </div>
      <div className="below-img">{pginfo}</div>
    </div>
  );
}

function ZoomScan({ pgurl, sclass }) {
  return (
    <ReactImageMagnify
      {...{
        smallImage: {
          alt: "page image",
          isFluidWidth: true,
          src: pgurl,
          width: 1000,
          height: 200,
        },
        largeImage: {
          src: pgurl,
          width: 2500,
          height: 500,
        },
        enlargedImagePosition: "over",
      }}
      className={sclass}
    />
  );
}
