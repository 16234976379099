import React from "react";
import { Link } from "react-router-dom";
import { useSolr } from "../hooks/useSolr";
import THLSkeleton from "./THLSkeleton";
import { capitalize } from "./utils";
import {NavSearch} from "../search/NavSearch";

export default function NavHead(props) {
  const query = {
    q: "type:tibbibl AND subtype:collection",
    fl: "*",
  };
  const {
    isLoading: isCollInfoLoading,
    data: collInfo,
    isError: isCollInfoError,
    error: collInfoError,
  } = useSolr(query);

  if (isCollInfoLoading) {
    return <THLSkeleton />;
  }

  if (isCollInfoError) {
    console.warn(
      "Error loading NavHead! Unable to get collection information",
      collInfoError
    );
    return null;
  }

  const colllinks = collInfo.docs.map((coll, ci) => {
    return (
      <li className="nav-item" key={["colllink", coll?.id, ci].join("-")}>
        <a
          className="nav-link"
          href={`/catalog/${coll.coll}/eds`}
          title={coll.title_en}
          key={`${coll.col}-${ci}`}
        >
          {capitalize(coll.coll)}
        </a>
      </li>
    );
  });
  let solrlink = "/solr/#/thl_solr/query";
  if (window.location.hostname === "localhost") {
    solrlink = "http://localhost:8983/solr/#/thlcat/query";
  }
  const gotoAdmin = () => {
    window.location.port = "8108";
  };
  return (
    <header style={{ border: "thin dashed white" }}>
      <nav
        id="catalog-nav"
        className="navbar navbar-expand-md navbar-light bg-light fixed-top"
      >
        <a className="navbar-brand" href="/">
          <img
            className="logo-image"
            src="/images/logo-THL-2010-tp.png"
            height="55px"
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="site-title-wrap"><p className="site-title">Tibetan and Himalayan Library</p></div>

        <div className="collapse navbar-collapse justify-content-between" id="navbarSupportedContent">
          <ul id="main-menu" className="navbar-nav flex-grow-1">
            <li className="nav-item">
              <a className="nav-link" href="http://new.thdl.org/">
                Home
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link" href="http://new.thdl.org/#/collections/all/deck">
                Collections
              </a>
              {/*
              <ul>
                <li>
                  <a className="nav-link" href="http://new.thdl.org/audio-video">
                    Audio-Video
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="http://new.thdl.org/images">
                    Images
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="http://new.thdl.org/sources">
                    Sources
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="http://new.thdl.org/texts">
                    Texts
                  </a>
                </li>
              </ul>
              */}
            </li>

            <li className="nav-item">
              <a className="nav-link" href="http://new.thdl.org/places">
                Places
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link" href="http://new.thdl.org/subjects">
                Subjects
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link" href="http://new.thdl.org/terms">
                Terms
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link" href="http://new.thdl.org/contribute">
                Contribute
              </a>
            </li>

            <li className="nav-item active">
              <a className="nav-link" href="/catalog">
                Catalogs
              </a>
            </li>

            {/*
            {colllinks}
            */}
          </ul>
          <div id="search-box" className="navbar-nav">
            <NavSearch />
          </div>
          <ul id="admin-menu" className="navbar-nav">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Admin
              </a>
              <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="navbarDropdown"
              >
                <a
                  className="dropdown-item"
                  href={process.env.REACT_APP_DJANGO_BASE}
                >
                  Catalog Admin
                </a>
                <a className="dropdown-item" href={solrlink} target="_blank">
                  Solr
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}
