import React from "react";
import { useBiblStore } from "../hooks/useBiblStore";
import { zfill } from "./catalogUtils";
import { useBibl } from "../hooks/useBibl";
import THLSkeleton from "../common/THLSkeleton";
import BiblRecord from "./biblrecord/BiblRecord";

/**
 * DoxVolBiblDisplay : Display a vol bibl in a dox view
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function DoxVolBiblDisplay() {
  const bibl = useBiblStore((state) => state.doc);
  const vnum = zfill(bibl?.pos_i, 3);
  const volbibid = `${bibl?.coll}-${bibl?.edsig}-v${vnum}-bib`;
  const {
    isLoading: isBiblLoading,
    data: volbibl,
    isError: isBiblError,
    error: biblError,
  } = useBibl(volbibid);

  if (isBiblLoading) {
    return <THLSkeleton />;
  }
  if (isBiblError) {
    console.log(`Problem loading vol bibl (${volbibid})`, biblError);
    return <p>Could not load volume bibl for {volbibid}.</p>;
  }
  return <BiblRecord customBibl={volbibl} />;
}
