import SplitPane, {Pane} from "react-split-pane";
import SearchResults from "./SearchResults";
import SearchFacets from "./SearchFacets";
import React, {useEffect, useState} from "react";
import {useBiblStore} from "../hooks/useBiblStore";
import {useSearchStore} from "../hooks/useSearchStore";
import {Redirect, useParams} from "react-router-dom";
import {useCookies} from "react-cookie";

const CFDBUG = false;

export function SearchPage() {
    const search = useSearchStore();
    const [dragged, setDragged] = useState(false); // Drag state for split screen view
    // Cookies for search
    const cookie_names = [
        'thlcat_searchactive',
        'thlcat_searchfilters',
    ];
    const [cookies, setCookie, removeCookie] = useCookies(cookie_names);

    useEffect(() => {
        search.setShowing(true);
        if (cookies?.thlcat_searchfilters) {
            search.setFilters(cookies.thlcat_searchfilters);
        }
        return () => search.setShowing(false);
    }, []);

    if (!search.isActive && !cookies?.thlcat_searchactive) {
        return <Redirect to={"/catalog"} />;
    }

    const startDrag = () => {
        setDragged(true);
    };
    const endDrag = () => {
        setDragged(false);
    };

    return (
        <>
            <SplitPane
                split="vertical"
                className="l-catalog-frame"
                defaultSize="65vw"
                minSize={10}
                onDragStarted={startDrag}
                onDragFinished={endDrag}
            >
                <Pane className="l-frame-content">
                    <div className="l-frame-wrapper">
                        <SearchResults dragged={dragged}/>
                    </div>
                </Pane>
                <Pane className="l-frame-toc">
                    <div className="l-frame-wrapper">
                        <h1 className="h6 mt-1 font-italic">Search Facets</h1>
                        <SearchFacets dragged={dragged}/>
                    </div>
                </Pane>
            </SplitPane>
        </>
    );
}
