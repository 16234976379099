import React from "react";
import { capitalize, langcode } from "../common/utils";
import {Link} from "react-router-dom";

// TODO: Put Coll data into SOLR and remove from react code
export const defaultCollNames = {
  kt: {
    en: {
      key: false,
      lang: "en",
      name: "Kangyur/Tengyur",
    },
    bo: {
      key: false,
      lang: "bo",
      name: "བཀའ་འགྱུར་དང་བསྟན་འགྱུར།",
    },
  },
  ngb: {
    en: {
      key: false,
      lang: "en",
      name: "Collection Tantras of the Ancients",
    },
    bo: {
      key: false,
      lang: "bo",
      name: "རྙིང་མ་རྒྱུད་འབུམ།",
    },
  },
};

export function getCollNames(coll) {
  console.log("coll in get collnames", coll);
  if (!(coll in defaultCollNames)) {
    return false;
  }
  const colldata = defaultCollNames[coll];

  const colllangs = Object.keys(colldata);
  const collnames = colllangs.map((langkey, kind) => {
    return colldata[langkey];
  });
  return collnames;
}

/**
 * Returns a value from the edbibl where field name is like "resource_{type}_s", such as
 *     fields like "resource_scans_s" or "resource_has_sides_s". If the value is a boolean or a number,
 *     it casts the return value into that type
 *
 * @param bibl
 * @param rname
 * @returns {boolean|number|*}
 */
export function getResourceVal(bibl, rname) {
  const ky = `resource_${rname}_s`;
  if (Object.keys(bibl).includes(ky)) {
    let val = bibl[ky];
    if (val === "false") {
      return false;
    }
    if (val === "true") {
      return true;
    }
    if (!isNaN(val) && !val.includes(".")) {
      // don't turn paginations into floats.
      return val * 1;
    }
    return val;
  }
}

export function getMainTitle(
  bibl,
  lang2show = process.env.REACT_APP_DEFAULT_LANG
) {
  if (!bibl || typeof bibl === "string") {
    return {
      idpref: "",
      title: "No bibl",
      lang: "en",
    };
  }
  const titles = Array.isArray(bibl["titles"])
    ? bibl["titles"]
    : [bibl["titles"]];

  const title_idpref =
    bibl.subtype === "volume" ? `Volume ${bibl.vol_num * 1}: ` : "";

  let title_to_use = titles[0]; // Show first title in list by default (if none more appropriate found)
  // let lang_to_use = lang2show;
  // Look through list for: normalized title, front title, and closing title and use those in that order
  for (let n in titles) {
    let atitle = titles[n];
    if (
      atitle?.type_title_s === "normalized-title" ||
      atitle?.type_title_s === "text" // for NGB
    ) {
      title_to_use = atitle;
      break;
    } else if (
      atitle?.type_title_s === "front" &&
      atitle?.subtype_title_s === "title-line"
    ) {
      title_to_use = atitle;
      break;
    } else if (
      atitle?.type_title_s === "back" &&
      atitle?.subtype_title_s === "closing-section"
    ) {
      title_to_use = atitle;
    }
  }

  // Get the title based on lang2show
  let ttitle = "Untitled";
  let tlang = "en";
  if (typeof title_to_use === "object") {
    const ttlfld = `title_${lang2show}`;
    if (Object.keys(title_to_use).includes(ttlfld)) {
      ttitle = title_to_use[ttlfld];
    } else if (Object.keys(title_to_use).includes("title")) {
      const ttutitle = title_to_use["title"];
      if (Array.isArray(ttutitle) && ttutitle.length > 0) {
        ttitle = ttutitle[0];
      } else if (typeof ttutitle === "string") {
        ttitle = ttutitle;
      }
    }
  }

  if (ttitle !== "Untitled" && lang2show) {
    tlang =
        title_to_use && title_to_use["lang"][0]
            ? title_to_use["lang"][0]
            : lang2show;
  }

  // Return title object with idpref, title, and lang
  return {
    idpref: title_idpref,
    title: ttitle,
    lang: tlang,
  };
}

/**
 * Gets titles from doxcat text record for Dox toc volumes
 *
 * @param doxcat
 * @param biblang
 * @returns {{idpref: string, title: string, lang}}
 */
export function getDoxTitle(doxcat, biblang = "default") {
  let title_idpref = "";
  let tfield = `title_${biblang}`;
  let ttitle = "Untitled.";
  if (Object.keys(doxcat).includes(tfield)) {
    ttitle = doxcat[tfield];
  } else if (typeof doxcat?.title === "string") {
    ttitle = doxcat.title;
  } else if (doxcat?.title?.length > 0) {
    ttitle = doxcat.title[0];
  }
  return {
    idpref: title_idpref,
    title: ttitle,
    lang: biblang,
  };
}

export function getDoxTitleSimple(doxcat, biblang = "default") {
  let doc_title = "No Title.";
  if (typeof doxcat === "undefined") {
    return doc_title;
  }
  const doclang = biblang === "default" ? getNormalizedLang(doxcat) : biblang;
  const titlefld = `title_${doclang}`;
  if (Object.keys(doxcat).includes(titlefld)) {
    doc_title = <span className={doclang}>{doxcat[titlefld]}</span>;
  } else if (Array.isArray(doxcat?.title) && doxcat.title.length > 0) {
    doc_title = doxcat.title[0];
  } else if (doxcat?.subtype === "volume") {
    doc_title = doxcat?.label_s ? doxcat.label_s : `Volume ${doxcat?.pos_i}`;
  }
  return doc_title;
}

export function getDoxRange(doxcat, debug=false) {
  const coll = doxcat?.coll;
  const ed = doxcat?.edsig;
  const pref = buildSigla(coll, ed);
  const txtnums = doxcat?.texts_ss?.map((tstr, i) => {
    return tstr * 1;
  });

  // TODO:
  if (!txtnums || doxcat?.children_ss?.length > 0) {
    return `${pref}${doxcat?.st_text_i}-${pref}${doxcat?.end_text_i}`;
  }
  const txtrngs = makeRanges(txtnums);
  return `${pref}${txtrngs.join(', ')}`;
}

export function makeRanges(numlist) {
  if(!Array.isArray(numlist)) {
    console.log("Numlist is not an array", numlist)
    return [numlist];
  }
  // Taken from https://stackoverflow.com/a/28431102 (July 20, 2023)
  const rlists = numlist.reduce((r, n) => {
    const lastSubArray = r[r.length - 1];
    if(!lastSubArray || lastSubArray[lastSubArray.length - 1] !== n - 1) {
      r.push([]);
    }
    r[r.length - 1].push(n);
    return r;
  }, []);

  return rlists.map((rl, i) => {
    const item1 = rl[0] * 1;
    const itemlast = rl.pop() * 1 ;
    if (item1 === itemlast) {
      return item1;
    }
    return `${rl[0]}-${itemlast}`;
  })
}

export function buildSigla(coll, edsig) {
  coll = capitalize(coll);
  let edsigcalc = "";
  if (edsig !== "main" && edsig?.length > 0) {
    edsigcalc = "." + capitalize(edsig);
  }
  return `${coll}${edsigcalc}.`;
}

/**
 * Takes a bibl titles list and finds the title for the given language
 * @param titles
 * @param lang
 */
export function getTitleByLang(titles, lang) {
  if (!Array.isArray(titles)) {
    // console.log("in getTitle by Lang titles is not an array", titles);
    return null;
  }
  const langTitles = titles.filter((t, tind) => {
    return t.lang && t.lang?.length > 0 && t.lang[0] === lang;
  });
  let outtitles = langTitles.map((t, tind) => {
    let title = t.title;
    let langtitle = t[`title_${lang}`];
    if (typeof langtitle !== "undefined") {
      title = langtitle;
    }
    if (Array.isArray(title)) {
      title = title[0];
    }
    return <span className={lang}>{title}</span>;
  });

  return outtitles?.length > 0 ? (
    outtitles[0]
  ) : (
    <span className="en">Untitled</span>
  );
}

export function hasField(obj, fnm) {
  const okeys = Object.keys(obj);
  return okeys?.includes(fnm);
}

export function solrFieldValue(solrfield) {
  if (Array.isArray(solrfield) && solrfield.length > 0) {
    return solrfield[0];
  }
  return solrfield;
}

/**
 * Function to return a span with class with the language code contained in the field name
 * Assumes the lang code is right after field name prefix (fnp)
 *
 * @param bibl
 * @param fnm
 */
export function getLangField(bibl, fnp) {
  const flds = getFieldsByString(bibl, fnp, "pref");
  const pattern = new RegExp(fnp + "_([^_]+)(_|$)"); // Find the next string between underscores after prefix (fnp)
  return (
    <>
      {flds.map((fldnm, fldind) => {
        let mmtch = fldnm.match(pattern);
        if (mmtch) {
          let lcd = langcode(mmtch[1]);
          return (
            <span key={`${fnp}-lang-${fldind}`} className={lcd}>
              {bibl[fldnm]}
            </span>
          );
        }
        return null;
      })}
    </>
  );
}

export function getLangFromString(instr) {
  let lng = 'en';
  const c1cd = instr.charCodeAt(0);
  if (c1cd > 3839 && c1cd < 4096) {
    lng = 'bo';
  } else if (c1cd > 2303 && c1cd < 2432) {
    lng = 'sa';
  } else if (c1cd > 19967 && c1cd < 40960) {
    lng = 'zh';
  }
  return lng;
}


export function getBiblId(bibl) {
  if (!bibl || !bibl?.coll) {
    return "";
  }
  if (bibl.dox !== "") {
    return bibl.dox;
  }
  let biblstr = `${bibl.coll}-${bibl.ed}`;
  if (bibl.text) {
    biblstr += `-${zfill(bibl.text, 4)}`;
  } else if (bibl.vol) {
    biblstr += `-v${zfill(bibl.vol, 3)}`;
  }
  biblstr += "-bib";
  return biblstr;
}

export function getBiblLang(bibl) {
  let lang = false;
  if (bibl["langcode"]) {
    lang = bibl["langcode"];
  }
  if (!lang && bibl["lang"]) {
    lang = bibl["lang"];
  }
  if (Array.isArray(lang)) {
    lang = lang[0];
  }
  return langcode(lang);
}

export function getLangTypedField(bibl, pref, suff) {
  let langcode = getBiblLang(bibl);
  const fieldval = getLangTypedFieldVal(bibl, pref, suff);
  if (fieldval) {
    return <span className={langcode}>{fieldval}</span>;
  } else {
    return null;
  }
}

/**
 * Gets a specific field with a langcode appended to the field name
 *
 * @param bibl
 *    The object with the data fields, generally a bibl but can be a section object
 * @param pref
 *    The string prefixed to the lang code, e.g. if you are looking for a specific lang title, "title" would become
 *    "title_bo", etc.
 * @param suff
 *    Any suffix after the lang code, if there were .e.g. "title_bo_weird" this would be "weird".
 * @returns {*|boolean}
 */
export function getLangTypedFieldVal(bibl, pref, suff) {
  const langcode = getBiblLang(bibl);
  if (langcode === "bod") {
    console.log("Langcode: ", langcode);
  }
  let field = `${pref}_${langcode}`;
  if (typeof suff !== "undefined") {
    field += `_${suff}`;
  }
  if (Object.keys(bibl).includes(field)) {
    return solrFieldValue(bibl[field]);
  }
  if (field === "ed_bod") {
    console.log("edbod", bibl);
  }
  // console.log(`Could not find ${field} in bibl data`);
  return false;
}

export function getDefaultField(doc, fieldname) {
  const deflang = process.env?.REACT_APP_DEFAULT_LANG || 'en';
  let field_val = doc[fieldname]; // default value without lang
  const fnlang = `${fieldname}_${deflang}`;
  if (Object.keys(doc).includes(fnlang)) {
    field_val = doc[fnlang];
  }
  if (field_val?.length > 0) {
    if (Array.isArray(field_val)) {
      return field_val[0];
    } else {
      return field_val;
    }
  }
  return false;
}

export function getBoField(bibl, pref, suff) {
  let tibval =
    typeof suff === "undefined"
      ? getLangTypedFieldVal(bibl, pref)
      : getLangTypedFieldVal(bibl, pref, suff);
  let wylval =
    typeof suff === "undefined"
      ? getLangTypedFieldVal(bibl, pref, "wy")
      : getLangTypedFieldVal(bibl, pref, suff + "_wy");
  if (wylval && typeof wylval !== "undefined") {
    return (
      <span className="bo" title={`${wylval}`} data-wylie={wylval}>
        {tibval}
      </span>
    );
  }
  return <span className="bo">{tibval}</span>;
}

export function getNormalizedLang(obj) {
  let lang = obj?.lang;
  if (!lang) {
    return process.env.REACT_APP_DEFAULT_LANG;
  }
  lang = Array.isArray(lang) ? lang[0] : lang;
  lang = langcode(lang);
  return lang && lang?.length === 2 ? lang : process.env.REACT_APP_DEFAULT_LANG;
}

/**
 * Finds field names in an object that contain a string in a certain position
 * @param {object} obj - the object being searched
 * @param {string} sstr - the substring to be found in field names
 * @param {string} pos -  optional position where the substring is location:
 *                        "pref" : as a prefix at the beginning of the field name
 *                        "suff" : suffix at the end of the field name
 *                        "contains" : (default) anywhere in the field name
 * @returns {string[]}
 */
export function getFieldsByString(obj, sstr, pos = "contains") {
  const keys = Object.keys(obj);
  return keys.filter((k) => {
    if (pos === "pref") {
      return k.startsWith(sstr);
    } else if (pos === "suff") {
      return k.endsWith(sstr);
    } else {
      return k.includes(sstr);
    }
  });
}

export function getPagination(bibl, abbrev = true) {
  const vollabel = abbrev ? "vol." : "volume";
  const stvol = bibl?.vol_start_i || bibl?.vol_num;
  const endvol = bibl?.vol_end_i || bibl?.vol_num;

  let stpg = bibl?.pg_start_text_s || bibl?.pg_start_s;
  let endpg = bibl?.pg_end_text_s || bibl?.pg_end_s;

  stpg = onePageMarkup(stpg, stvol, vollabel);
  if (stvol === endvol) {
    endpg = onePageMarkup(endpg);
  } else {
    endpg = onePageMarkup(endpg, endvol, vollabel);
  }

  return (
    <span className="bibl__pagination">
      {stpg} - {endpg}
    </span>
  );
}

export function onePageMarkup(folio, volnum = "", vollabel = "") {
  const volmarkup =
    volnum !== "" ? (
      <span className="bibl__page--vol">
        <span className="label">{vollabel}</span>{" "}
        <span className="num">{volnum}</span>
      </span>
    ) : null;
  return (
    <span className={"bibl__page"}>
      {volmarkup}
      <span className="bibl__page--folio">{folio}</span>
    </span>
  );
}

export function getNote(data) {
  let notefield = Object.keys(data).filter((kn, i) => {
    return kn.startsWith("note_");
  });
  if (notefield.length > 0) {
    // TODO: return more than one (maybe?)
    if (data[notefield[0]]) {
      let notedata = Array.isArray(data[notefield[0]]) ? data[notefield[0]][0] : data[notefield[0]];
      return notedata.replace(/\\[ntr]/g, " ");
    }
  }
  return false;
}

// Returns the value contained in solr field. If it's an array, returns first value.
export function getSolrVal(fval) {
  if (Array.isArray(fval)) {
    return fval[0];
  }
  return fval;
}

/**
 * Parses a edition resource string from a solr record. For instance, "resource_digtexts_s":"1-1117" or
 * "resource_scans_s":"0". This function takes the value such as "1-1117" or "0" and parses it into
 * an array of [start, end] numbers. Or returns false if the value is none-ish.
 *
 * This function could be enhanced to parse other values if necessary
 *
 * @param res
 * @returns {boolean|number[]}
 */
export function parseResourceRange(res) {
  if (!res || res * 1 === 0 || res === "" || res === "none") {
    return false;
  }
  // Parse input string differently depending on format
  if (!isNaN(res)) {
    // If it's a single number, cast it to a number
    res = res * 1;
    return [res];
  } else if (res.includes("-") || res.includes(",")) {
    // console.log("res", res);
    let rout = [];
    const respts = res.split(',');
    for (let pn in respts) {
      let rpt = respts[pn].trim();
      if (!isNaN(rpt)) {
        rout.push(rpt * 1)
      } else if (rpt.includes('-')) {
        // If it's a range, return an array of all numbers in the range
        const rngpts = rpt.split("-");
        if (!isNaN(rngpts[0]) && !isNaN(rngpts[1])) {
          const st = rngpts[0] * 1;
          const en = rngpts[1] * 1;
          rout = [...rout, ...Array(en - st + 1).keys()].map((x) => {return x + st;} );
        }
      }
    }
    return rout;
  }
  return res;  // Otherwise, return the original string unparsed
}

export function inRange(anum, arange) {
  anum = anum * 1;
  // If the range is a list with more than two terms, it is an individual list, just see if number is in it.
  if (arange?.length > 2) {
    return inList(anum, arange);
  }
  const startnum = arange?.length > 0 ? arange[0] * 1 : false;
  let endnum = arange?.length > 1 ? arange[1] * 1 : startnum;

  if (isNaN(anum) || !startnum) {
    return false;
  }
  return anum >= startnum && anum <= endnum;
}


export function inList(anum, arange, sep=',') {
  anum = anum * 1;
  if (typeof (arange) === 'string') {
    arange = arange.split(sep);
    arange = arange.map((it, itn) => {
      try {
        return parseInt(it);
      } catch (e) {
        return 0;
      }
    });
  }
  // If the range is a list with more than two terms, it is an individual list, just see if number is in it.
  if (Array.isArray(arange)) {
    return (arange.indexOf(anum) > -1);
  }
}

export function getPagePath(edbib, pg) {
  /*
resource_digtexts_s: "0"
resource_master-cat_s: "ngb/ng/ngb-ng-cat.xml"
resource_master_id_s: "ng-work-bib"
resource_pgimg_dir_s: "https://www.thlib.org/static/catalogs/ngb/tb/"
resource_pgimg_id_s: "ngb-tb-v__VOL__-p__PAGE__"
resource_pgimg_path_s: "v__VOL__/__PGID__.jpg"
resource_scans_s: "1-939"  // Text numbers that have scans
resource_txtpg_id_s: "v__VOL__p__PAGE____SIDE__"
resp_cataloguer_s: "ndg"
resp_history_of_the_edition_s: "dfg"
 */
  // console.log("pg in get path", pg);
  // console.log("edbib", edbib);
  const zvol = zfill(pg?.vol, 3);
  const zpg = zfill(pg?.page, 4);
  let pgvars = {
    vol: zvol,
    page: zpg,
  };
  if (pg?.side?.length > 0) {
    pgvars["side"] = pg.side;
  }
  const pgimg_id = translateResource(edbib?.resource_pgimg_id_s, pgvars);
  let pgimg_path = translateResource(edbib?.resource_pgimg_path_s, {
    vol: zvol,
    pgid: pgimg_id,
  });
  pgimg_path = edbib?.resource_pgimg_dir_s + pgimg_path;
  // console.log("pgimg path in function", pgimg_path);
  return pgimg_path;
}

/**
 * Takes a resource string such as "resource_pgimg_id_s":"kt-d-v__VOL__-p__PAGE__", and an object maded up of
 * repstr => val pairs, e.g. {"vol": 23, "page": "67b"} and translates the generic string into the  specific one
 *
 * @param resstr
 * @param vals
 * @returns {*}
 */
export function   translateResource(resstr, vals) {
  if (typeof resstr !== "string" || typeof vals !== "object") {
    return resstr;
  }
  Object.keys(vals).map((ky, kn) => {
    resstr = resstr.replace(`__${ky.toUpperCase()}__`, vals[ky]);
  });
  return resstr;
}

/**
 * Parses a pagination string into an object. Can have start and end pagination separated by a dash
 * or just a single start pagination. Paginations can include volume, e.g., v1:245a.6-v1:258b.3 or not, 314b.2
 * For now it requires a side, a or b. TODO: generalize so side is not necessary.
 *
 * @param pgn
 * @returns {{}|boolean}
 */
export function parsePagination(pgn) {
  if (!pgn || typeof pgn !== "string") {
    return false;
  }
  // Pagination Format is:      v1:245a.6-v1:258b.3
  const pgnpt = pgn.split("-");
  let pgobj = {};
  if (pgn[0] === 'v') {
    const rx = /v(\d+):(\d+)([ab])\.(\d)/;
    const res = pgnpt[0]?.match(rx);
    if (res) {
      pgobj["hasvol"] = true;
      pgobj["start"] = {
        vol: res[1],
        page: res[2],
        side: res[3],
        line: res[4],
      };
    }

    const res2 = pgnpt[1]?.match(rx);
    if (res2) {
      pgobj["end"] = {
        vol: res2[1],
        page: res2[2],
        side: res2[3],
        line: res2[4],
      };
    }
  } else {
    pgobj["hasvol"] = false;
    const rx2 = /(\d+)([ab])\.(\d)/;
    const res = pgnpt[0]?.match(rx2);
    if (res) {
      pgobj["start"] = {
        page: res[1],
        side: res[2],
        line: res[3],
      };
    }

    const res2 = pgnpt[1]?.match(rx2);
    if (res2) {
      pgobj["end"] = {
        page: res2[1],
        side: res2[2],
        line: res2[3],
      };
    }
  }

  return pgobj;
}

export function paginationToString(pgn) {
  // For some instances of strings like: v1:34b.7-v1:45b.6
  const mtch = pgn.match(/v(\d+):(\d+)([ab])\.(\d)-v(\d+):(\d+)([ab])\.(\d)/);
  if (mtch) {
    pgn = {
      start: {
        vol: mtch[1],
        page: mtch[2],
        side: mtch[3],
        line: mtch[4],
      },
      end: {
        vol: mtch[5],
        page: mtch[6],
        side: mtch[7],
        line: mtch[8],
      },
    };
  }
  const st = pgn?.start;
  if (!st) {
    return "";
  }
  const en = pgn?.end;
  let pgnstr = `Vol. ${st.vol}, ${st.page}${st.side}.${st.line}`;
  if (en) {
    pgnstr += "-";
    if (en.vol !== st.vol) {
      pgnstr += `Vol. ${en.vol}, `;
    }
    pgnstr += `${en.page}${en.side}.${en.line}`;
  }
  return pgnstr;
}

export function inPaginationRange(pgdata, pagination) {
  // pgdata = array[vol, pg, side]
  // pagination format: v1:99a.2-v1:111a.4
  const pgpts = pagination.split("-");
  const startpg = pageStrToObj(pgpts[0]);
  let endpg = startpg;
  if (pgpts?.length == 2) {
    endpg = pageStrToObj(pgpts[1]);
  }
  return pageInRange(...pgdata, startpg, endpg);
}

export function pageStrToObj(pgstr) {
  let mtch = pgstr.match(/v(\d+):(\d+)([ab])\.?(\d*)/);
  if (mtch) {
    return {
      vol: mtch[1] * 1,
      page: mtch[2] * 1,
      side: mtch[3],
      line: mtch[4] ? mtch[4] * 1 : "",
    };
  }
  mtch = pgstr.match(/v(\d+)p(\d+)([ab]?)/);
  if (mtch) {
    return {
      vol: mtch[1] * 1,
      page: mtch[2] * 1,
      side: mtch[3],
    };
  }
  return false;
}

export function pageInRange(vol, pg, sd, stpg, endpg) {
  vol = vol * 1;
  if (vol < stpg.vol || vol > endpg.vol) {
    return false;
  }
  pg = pg * 1;
  if (pg < stpg.page || pg > endpg.page) {
    return false;
  }
  return !(
    (pg === stpg.page && sd < stpg.side) ||
    (pg === endpg.page && sd > endpg.side)
  );
}

export function prevPage(pg, sd) {
  if (sd === "a") {
    sd = "b";
    pg = pg * 1 - 1;
    if (pg < 1) {
      pg = 1;
    }
  } else {
    sd = "a";
  }
  return [pg, sd];
}

export function nextPage(pg, sd) {
  if (sd === "a") {
    sd = "b";
  } else {
    pg = pg * 1 + 1;
    sd = "a";
  }
  return [pg, sd];
}

/**
 * Sorts a list of bibl objects by a field that is itself a list.
 * Written to sort by title in SOLR docs which are lists and cannot be
 * @param blist
 * @param field
 */
export function sortBibls(blist, field = "title") {
  return blist.sort((x, y) => {
    let xf = x[field];
    let yf = y[field];
    if (Array.isArray(xf) && xf.length > 0) {
      xf = xf[0];
    }
    if (Array.isArray(yf) && yf.length > 0) {
      yf = yf[0];
    }
    if (xf < yf) {
      return -1;
    }
    if (xf > yf) {
      return 1;
    }
    return 0;
  });
}

export function zfill(num, size) {
  if (!num) {
    return num;
  }
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}


/**
 * Converts XML id, e.g. 'b3-1-5-4' into a label '2.3.1.5.4'
 * @param sctid
 * @returns {*}
 */
export function convertToSectionLabel(sctid) {
  if (typeof(sctid) === "string") {
    sctid = sctid?.replace('a', '1.')
        .replace('b', '2.')
        .replace('c', '3.')
        .replace(/\-/g, '.');
  }
  return sctid;
}

/**
 * The reverse of above, turns a label back into an id: '3.1.2' => 'c1-2'
 * @param sctlabel
 * @returns {*}
 */
export function convertToSectionId(sctlabel) {
  if (!sctlabel || !sctlabel?.split) { return ''; }
  let pts = sctlabel.split('.');
  let sectlet = 'b';
  if (pts[0] === '1') { sectlet = 'a'; }
  if (pts[0] === '3') { sectlet = 'c'; }
  pts.shift(); // remove first part
  pts[0] = sectlet + pts[0]; // join the section letter to the first section number
  return pts.join('-');
}

/**
 * Takes a section title with a mixed string of number and title text and splits it into
 * an array of the number and title. The section number can be any combination of digits and periods.
 *
 * @param title
 * @returns {(string|*)[]|*[]}
 */
export function splitNumberedTitle(title) {
  if (typeof(title) !== 'string') { return title; }
  const regex = /([\d\.]+)\s*([\s\S]+)/; // splits off a preceding combination of digits and periods
  const found = title.match(regex);
  if (found) {
    return [found[1], found[2]];
  }
  return ["", title];  // returns a two item array with item 1 the number (if found) and item 2 the title
}


export function getLinkFromId(iid) {
  if (typeof(iid) !== 'string' || !iid.includes('-')) { return iid; }
  const idpts = iid.split('-');
  // Section ID when no ed in url as in LCCW
  if (idpts?.length === 5 && idpts[2] === 'text' && idpts[4] === 'text') {
    const [coll, tnum, t1, sid, t2] = idpts;
    return <Link to={`/catalog/${coll}/main/${tnum}/text/section/${sid}`}>View</Link>;
  }
}

/**
 * Function to parse master dox ids (only for NGB for now)
 * TODO: Generalize for other master catalogs...
 * @param did
 * @returns {string}
 */
export function parseMasterDoxId(did) {
  let newid = '';
  switch (did) {
    case 'ngb-ng1':
      newid = 'ngb-ng-ati';
      break;
    case 'ngb-ng2':
      newid = 'ngb-ng-anu';
      break;
    case 'ngb-ng3':
      newid = 'ngb-ng-maha';
      break;
    case 'ngb-ng4':
      newid = 'ngb-ng-supp';
      break;
    case 'ngb-ng5':
      newid = 'ngb-ng-app';
      break;
    default:
      newid = did.replace("ngb-ng", "ngb-ng-").replace('--', '-');
  }
  return newid + '-dox'
}

