import "./css/CatalogFrame.scss";
import { useSolr } from "../hooks/useSolr";
import THLSkeleton from "../common/THLSkeleton";
import React from "react";
import { CollInfo } from "./CollectionSummary";
import "./css/CatalogFrame.scss";
import { sortBibls } from "./catalogUtils";
import {Link} from "react-router-dom";

export default function CatalogSummary() {
  return (
    <div className="l-catalog-frame">
      <div className="summary">
        <h1>Catalog Overview</h1>
        <p>The following catalogs are available:</p>
        <div className="collections">
          <CollList />
        </div>
      </div>
    </div>
  );
}

function CollList() {
  const query = {
    q: "type:tibbibl AND subtype:collection",
    fl: "*",
  };
  let {
    isLoading: isCollInfoLoading,
    data: collInfo,
    isError: isCollInfoError,
    error: collInfoError,
  } = useSolr(query);

  if (isCollInfoLoading) {
    return <THLSkeleton />;
  }

  if (isCollInfoError) {
    return (
      <p>
        <strong>Notice:</strong> Unable to contact the SOLR index (
        {collInfoError?.message}).
      </p>
    );
  }

  if (!collInfo?.docs || collInfo.docs.length === 0) {
    return (
      <p>
        No collections found in the SOLR index. Please talk to your
        administrator.
      </p>
    );
  }
  collInfo = sortBibls(collInfo.docs, "title_en");

  return (
    <>
      {collInfo?.map((d, i) => {
        return <CollInfo key={`coll-list-item-${i}`} doc={d} showEd={false} />;
      })}
    </>
  );
}
