import { useQuery } from "react-query";
import axios from "axios";
import jsonpAdapter from "../common/axios-jsonp";
import { processFacets } from "../common/utils";

/**
 * Queries the SOLR index defined by process.env.REACT_APP_SOLR_URL.
 * Takes a single parameter (params) which is an object of the SOLR parameters
 *
 * @param params
 * @param byPass
 * @returns {UseQueryResult<any, unknown>}
 */
export function useSolr(params = {}, byPass = false) {
  const qkey = getParamStrings(params);
  if (params.q === 'text:') { params.q = 'text:*'; }
  return useQuery(qkey, () => querySolr(params), {
    enabled: !byPass,
  });
}

export function getParamStrings(params) {
  let plist = [];
  Object.keys(params).forEach((pk) => {
    let val = params[pk];
    if (pk === 'q' && val === 'text:') {
      val = 'text:*'
    }
    plist.push(`${pk}=${val}`);
  });
  return plist;
}

const querySolr = async (params) => {
  let request = {
    url: process.env.REACT_APP_SOLR_URL,
    adapter: jsonpAdapter,
    dataType: 'jsonp',
    callbackParamName: "json.wrf",
    headers: {
      'Content-Type': null
    },
    params: {},
    transformResponse: function (data) {
      let newdata = data && data.response ? data.response : data;
      if (data?.facet_counts?.facet_fields) {
        newdata.facets = processFacets(data.facet_counts);
      }
      if (data?.stats?.stats_fields?.pnum_i) {
        newdata.min = data.stats.stats_fields.pnum_i?.min;
        newdata.max = data.stats.stats_fields.pnum_i?.max;
      }
      // Buckets for grouping hits by text regardless of record type
      if (data?.facets?.texthits?.buckets) {
        newdata.buckets = data.facets.texthits.buckets;
      }
      if (data?.highlighting) {
        newdata.highlighting = data.highlighting;
      }
      for (let i = 0; i < newdata?.docs?.length; i++) {
        let doc = newdata.docs[i];
        if (!doc?.titles) {
          doc.titles = [];
          doc.sections = [];
          for (let n=0; n < doc?._childDocuments_?.length; n++) {
            let child = doc?._childDocuments_[n];
            if(child?.type === "title") {
              doc.titles.push(child);
            } else if (child?.type === "chapter" || child?.type === "chapgroup") {
              doc.sections.push(child);
            }
          }
        }
        newdata.docs[i] = doc;
      }
      return newdata;
    },
  };

  // console.log("query solr params", params);
  const pmkeys = Object.keys(params);

  if (pmkeys.length > 0) {
    request["params"] = params;
    if (!pmkeys.includes("fl")) {
      request["fl"] = "*,[child limit=1000]";
    }
  }
  // params["wt"] = "json";  // This does nothing???
  const { data } = await axios.request(request).catch(function (e) {
    console.error("error", e);
  });

  if(request?.params?.start) {
    console.log("data", data);
  }

  return data;
};
