import React from "react";
import { langNameFromCode } from "../../common/utils";
import {getSolrVal, hasField} from "../catalogUtils";
import {useSearchStore} from "../../hooks/useSearchStore";
import {HighlightTitleHit} from "../../search/HighlightTitleHit";

export function BiblTitles({ bibl }) {

  let btitles = Array.isArray(bibl.titles) ? bibl.titles : [bibl.titles];
  btitles = btitles.filter((ttle) => {
    return (
      typeof ttle === "object" && Object.keys(ttle).includes("type_title_s")
    );
  });

  // Get Normalized Titles
  const normtitles = btitles.filter((ttle) => {
    return ttle["type_title_s"].includes("normalized");
  });

  const isNorm = normtitles && normtitles.length > 0;

  // Get Edition Titles
  const edtitles = btitles.filter((ttle) => {
    return ttle["type_title_s"] === 'edition';
  });

  // Get Front, Body, and Back Titles
  const frontts = btitles.filter((ttle) => {
    return ttle["type_title_s"] === "front";
  });
  const bodytts = btitles.filter((ttle) => {
    return ttle["type_title_s"] === "body";
  });
  const backtts = btitles.filter((ttle) => {
    return ttle["type_title_s"] === "back";
  });

  // Get Other Titles
  const othertts = btitles.filter((ttle) => {
    return (
      !ttle["type_title_s"].includes("normalized") &&
      !(ttle["type_title_s"] === "edition") &&
      !(ttle["type_title_s"] === "front") &&
      !(ttle["type_title_s"] === "body") &&
      !(ttle["type_title_s"] === "back")
    );
  });

  const areVariants = ([...othertts, ...frontts, ...bodytts, ...backtts].length > 0);

  let notitle = (!isNorm && frontts?.length === 0 && bodytts?.length === 0 && backtts?.length === 0
      && othertts?.length === 0) ? <p>Untitled</p> : null;
  if (notitle && bibl?.subtype == 'edition' && bibl?.ed) {
    let deflang = process?.env?.REACT_APP_DEFAULT_LANG;
    notitle = <ul className="list-unstyled">
      <li><label>Title English</label> <span>{bibl?.ed}</span></li>
      { bibl[`ed_${deflang}`] && (<li>
        <label>Title {langNameFromCode(deflang)} </label>
        <span className={deflang}>{bibl[`ed_${deflang}`]}</span>
      </li>)}
      { bibl[`ed_${deflang}_latn`] && (<li>
        <label>Title Transliteration </label>
        <span>{bibl[`ed_${deflang}_latn`]}</span>
      </li>) }
    </ul>
  }
  return (
    <div className="c-bibl_titles">
      <h2>Title Information</h2>
      {isNorm && ( <NormTitle titles={normtitles} />)}
      {isNorm && areVariants && (<h3>Title Variants</h3>) }
      {edtitles?.length > 0 && (
          <EditionTitles titles={edtitles} />
      )}
      <NonBodyTitles type="front" titles={frontts} />
      <BodyTitles titles={bodytts} />
      <NonBodyTitles type="back" titles={backtts} />
      <OtherTitles titles={othertts} />
      {notitle}
    </div>
  );
}

function NormTitle({ titles }) {
  if (!titles || titles?.length === 0) {
    return null;
  }
  // For NGB Master Titles (there is one titles entry with multiple language titles)
  if (titles?.length === 1 && titles[0].title?.length > 1 && titles[0].lang?.length > 1) {
      titles = titles[0].lang.map((lng, li) => {
          const atitle = (hasField(titles[0], `title_${lng}`)) ? titles[0][`title_${lng}`] : titles[0].title[li];
          return {
              title: atitle,
              lang: lng
          };
      });
  }
  return (
    <div>
      <h3>Normalized Title</h3>
      <ul className="list-unstyled">
        {titles.map((t, ti) => {
          let ntitle = typeof t === "string" ? t : "No title found";
          if (typeof t === "object") {
            if (Object.keys(t).includes("title")) {
              ntitle = t;
            }
          }
          return <BiblTitle key={`normalized-title-${ti}`} title={ntitle} />;
        })}
      </ul>
    </div>
  );
}

function EditionTitles({ titles }) {
  const titlesout = titles.map((tl, tli) => {
    const lngcode = getSolrVal(tl?.lang);
    const lngname = langNameFromCode(lngcode);
    const fnm = `title_${lngcode}`;
    const title = (Object.keys(tl).includes(fnm)) ? tl[fnm] : titles[0];
    return (
        <li key={`edition-title-${lngcode}-${tli}`}>
          <span className={lngcode}>{title}</span>
          <span className="en"> ({lngname})</span>
        </li>
    );
  });
  return (
      <div key={"edition-title-div"}>
        <h4 className="text-capitalize">
          Edition Titles
        </h4>
        <ul className="list-unstyled">
          {titlesout}
        </ul>
      </div>
  );
}

function BiblTitle({ title }) {
  const sqry = useSearchStore((state) => state.getQuery());
  const searchActive = useSearchStore((state) => state.isActive);
  const source = "source" in title ? title["source"] : false;
  const btitle = (searchActive) ? <HighlightTitleHit title={title["title"]} sstr={sqry} /> : title["title"];
  return (
    <li>
      <span className={title["lang"]}>{btitle}</span>
      {source && source.length > 0 && <span> ({source})</span>}
    </li>
  );
}

function NonBodyTitles({ type, titles }) {
  if (!titles || titles?.length === 0) {
    return null;
  }
  return titles.map((tl, tli) => {
    const tlid = tl?.id ? tl.id : new Date().getTime();
    return (
      <div key={`${type}-${tlid}-${tli}`}>
        <h4 className="text-capitalize">
          {tl["subtype_title_s"].replace("-", " ")}
        </h4>
        <ul className="list-unstyled">
          <BiblTitle kval={`${type}-title-${tli}`} title={tl} />
        </ul>
      </div>
    );
  });
}

function BodyTitles({ titles }) {
  const sqry = useSearchStore((state) => state.getQuery());
  if (!titles || titles?.length === 0) {
    return null;
  }
  const markup = titles.map((tl, tli) => {
    let src = null;
    if (tl["source_ss"]) {
      src = tl["source_ss"].map((sstr, sstri) => {
          const srpts = sstr.split(":");
          let punc = '';
          if (sstri < tl["source_ss"].length - 1) {
              punc = (srpts.length === 1) ? ', ' : '; ';
          }
          const cont = (srpts.length > 1) ? <><strong>{srpts[0]}</strong>: {srpts[1]}</> : <span>{srpts[0]}</span>;
            return (
              <span key={`srcst-${tli}-${sstri}`}>
                {cont}{punc}
              </span>
            );
      });
      src = <li key={`title-item-${tli}`}>(Chapters: {src})</li>;
    }
    return (
      <ul key={`body-title-${tli}`} className="list-unstyled">
        <li className={tl["lang"]}><HighlightTitleHit title={tl["title"]} sstr={sqry} /></li>
        {src}
      </ul>
    );
  });

  return (
    <div>
      <h4>Text Titles Given in Chapters</h4>
      {markup}
    </div>
  );
}

function OtherTitles({ titles }) {
  const sqry = useSearchStore((state) => state.getQuery());
  if (!titles || titles?.length === 0) {
    return null;
  }

  return titles.map((tl, tli) => {
    let tlid = tl?.id ? tl.id : new Date().getTime();
    tlid += "-" + tli;
    let type = tl["type_title_s"].replace("ntibet", "n-Tibet");
    if (type === "volume") {
      type = tl["subtype_title_s"].replace("-", " ");
    }
    return (
      <div key={`${type}-title-div-${tlid}`}>
        <h4 className="text-capitalize">{type} Title</h4>
        <ul className="list-unstyled">
          {tl["lang"].map((tlang, tlli) => {
            let mytitle = tl[`title_${tlang}`];
            let mytittletype = null;
            if (tl[`titletype_${tlang}`]) {
              mytittletype = (
                <span className="text-capitalize">{tl[`titletype_${tlang}`]}{" "}</span>
              );
            }
            let langname = langNameFromCode(tlang);
            return (
              <li key={`${tlid}-${type}-title-${tlli}-${tlang}`}>
                <span className={tlang}>{mytitle}</span>
                <span>
                  {" "}
                  ({mytittletype}{langname})
                </span>
              </li>
            );
          })}
          {tl["pagination_s"] && (
            <li key={`${tlid}-${type}-title-source`}>
              <span> ({tl["pagination_s"]})</span>
            </li>
          )}
        </ul>
      </div>
    );
  });
}
