import create from "zustand";
import {
  nextPage,
  pageInRange,
  parsePagination,
  prevPage,
  zfill,
} from "../catalog/catalogUtils";
import axios from "axios";

/** ########## !!!!!!!!!!!! OLD - DEPRECATED !!!!!!!!!!!! ############# **/
/**
 * State data for the catalog based on zustand bear state module
 *
 * @type {UseStore<{getBiblType: function(): string, getBiblId: function(): string, reset: function(): *,
 * setBibl: function(*=): *, showBibl: function(): string, bibl: {coll: string, vol: string, text: string,
 * docat: string, ed: string, lang: string}}>}
 */
export const useCatalog = create((set, get) => ({
  params: {},
  bibl: {
    type: "ed",
    coll: process.env.REACT_APP_DEFAULT_COLLECTION,
    ed: process.env.REACT_APP_DEFAULT_EDITION,
    lang: process.env.REACT_APP_DEFAULT_LANG,
    ednames: {
      en: `${process.env.REACT_APP_DEFAULT_COLLECTION} ${process.env.REACT_APP_DEFAULT_EDITION}`,
    },
    vol: "",
    text: "",
    dox: "",
    doc: null,
  },
  edbibl: null,
  edtitles: null,
  volbibls: [],
  lang: "",
  view: "bibl", // current view mode of the catalog
  toctype: "dox",
  getBiblId: () => {
    const bibl = get().bibl;
    if (!bibl) {
      return "";
    }
    if (bibl.dox !== "") {
      return bibl.dox;
    }
    let biblstr = `${bibl.coll}-${bibl.ed}`;
    if (bibl.text) {
      biblstr += `-${zfill(bibl.text, 4)}`;
    } else if (bibl.vol) {
      biblstr += `-v${zfill(bibl.vol, 3)}`;
    }
    biblstr += "-bib";
    return biblstr;
  },
  getBiblType: () => {
    const bibl = get().bibl;
    return bibl.type;
  },
  getEdId: () => {
    const bibl = get().bibl;
    let edId = bibl.coll;
    if (bibl.ed !== "") {
      edId += `-${bibl.ed}`;
    }
    return edId;
  },
  getEdName: (lng) => {
    const ednms = get().bibl.ednames;
    if (Object.keys(ednms).includes(lng)) {
      return ednms[lng];
    }
    return false;
  },
  getPageId: (vol, pg, sd) => {
    // console.log("in get page id: ", `v ${vol}, pg ${pg}${sd}`);
    const edbibl = get().edbibl;
    const pgpatt = edbibl?.resource_txtpg_id_s;
    return pgpatt
      ?.replace("__VOL__", vol)
      .replace("__PAGE__", pg)
      .replace("__SIDE__", sd);
  },
  getPageImagePath: (vol, page, side) => {
    const bibl = get().bibl;
    vol = vol * 1; // remove zero padding
    page = page * 2;
    // Page number calculation is for kt-d TODO: generalize for all catalogs. Possibly store equivalents in SOLR?
    if (side === "b") {
      page++;
    }
    vol = zfill(vol, 3);
    page = zfill(page, 4);
    const pgpath =
      process.env.REACT_APP_IMAGE_REPO +
      `${bibl?.coll}/${bibl?.ed}/v${vol}/${bibl?.coll}-${bibl?.ed}-v${vol}-p${page}.png`;
    return pgpath;
  },
  getSection: (sectid) => {
    const bibl = get().bibl?.doc; //  .getSolrDoc();
    const sect = bibl?.sections?.filter((sct, si) => {
      return sct?.id === sectid;
    });
    return sect?.length > 0 ? sect[0] : false;
  },
  getSectionPagination: (sectid) => {
    const sect = get().getSection(sectid);
    if (sect?.length > 0 && sect[0]?.pagination_s) {
      return parsePagination(sect[0].pagination_s);
    }
    return false;
  },
  getSolrDoc: () => {
    const bibl = get().bibl;
    return bibl.doc;
  },
  getStartEndPages: () => {
    const bibl = get().bibl.doc;
    return [
      {
        vol: bibl?.vol_start_i * 1,
        page: bibl?.pg_start_i * 1,
        side: bibl?.pg_start_side_s,
      },
      {
        vol: bibl?.vol_end_i * 1,
        page: bibl?.pg_end_i * 1,
        side: bibl?.pg_end_side_s,
      },
    ];
  },
  setBibl: (biblval) => {
    set((state) => ({ bibl: biblval }));
    if (biblval?.lang?.length > 0) {
      set((state) => ({ lang: biblval.lang[0] }));
    }
  },
  setBiblFromParams: (params) => {
    let bibl = get().bibl;
    bibl.coll = params?.coll
      ? params.coll
      : process.env.REACT_APP_DEFAULT_COLLECTION;
    if (params?.ed) {
      bibl.ed = params.ed;
      bibl.type = "ed";
    }
    if (params?.vol) {
      bibl.vol = params.vol;
      bibl.type = "vol";
    } else {
      bibl.vol = "";
    }
    if (params?.text) {
      bibl.text = params.text;
      bibl.type = "text";
    } else {
      bibl.text = "";
    }
    if (params?.dox) {
      bibl.dox = params.dox;
      bibl.type = "dox";
    } else {
      bibl.dox = "";
    }
    if (params?.view) {
      set((state) => ({ view: params.view }));
    }
    set((state) => ({ bibl: bibl }));
    get().setEdInfo();
  },
  setEdInfo: async () => {
    let edbibl = get().edbibl;
    let volbibls = get().volbibls;
    if (edbibl) {
      // return if already set
      // TODO: need to add function to reset ed and check if switched
      return;
    }
    const edid = get().getEdId();
    let request = {
      url: process.env.REACT_APP_SOLR_URL,
      params: {
        q: `id:(${edid}-bib OR ${edid}-v*)`,
        fq: `type:tibbibl`,
        fl: `*,[child limit=1000]`,
        rows: 1000,
      },
    };
    const { data } = await axios.request(request).catch(function (e) {
      console.log("error", e);
    });
    if (data?.response && data.response?.numFound > 0) {
      edbibl = data.response.docs.filter((doc, di) => {
        return doc?.subtype === "edition";
      });
      volbibls = data.response.docs.filter((doc, di) => {
        return doc?.subtype === "volume";
      });
      edbibl = data && data.response ? data.response : data;
      if (edbibl.numFound > 0) {
        edbibl = edbibl.docs[0];
      }
      let edtitles = Array.isArray(edbibl.titles)
        ? edbibl.titles
        : [edbibl.titles];
      edtitles = edtitles.map((t, tn) => {
        const tlang = Array.isArray(t.lang) ? t.lang[0] : t.lang;
        const tfield = `title_${tlang}`;
        const title = Array.isArray(t[tfield]) ? t[tfield][0] : t[tfield];
        return {
          title: title,
          lang: tlang,
        };
      });
      set((state) => ({
        edbibl: edbibl,
        ednames: edtitles,
        volbibls: volbibls,
      }));
    } else {
      console.error("No edition bibl found for request:", request);
    }
  },
  setSolrDoc: (doc) => {
    const bibl = get().bibl;
    bibl.doc = doc;
    bibl.coll = doc.coll;
    bibl.ed = doc.edsig;
    set((state) => ({ bibl: bibl }));
  },
  setTocType: (ttype) => {
    set((state) => ({ toctype: ttype }));
  },
  showBibl: () => {
    const bibl = get().bibl;
    if (!bibl) {
      return "none";
    }
    let biblstr = `${bibl.coll}-${bibl.ed}`;
    if (bibl.vol) {
      biblstr += `-v${bibl.vol}`;
    }
    if (bibl.text) {
      biblstr += `-${bibl.text}`;
    }
    return biblstr;
  },
  reset: () =>
    set({
      bibl: {
        coll: "kt",
        ed: "d",
        vol: "",
        text: "",
        docat: "",
      },
    }),
}));
