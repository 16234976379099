import { Link, useParams } from "react-router-dom";
import { useSolr } from "../hooks/useSolr";
import THLSkeleton from "../common/THLSkeleton";
import { HtmlCustom } from "../common/HtmlCustom";
import React from "react";
import "./css/CatalogFrame.scss";

export function CollectionSummary() {
  const params = useParams(); // Get URL Params
  const coll = params?.coll;
  const query = {
    q: `type:tibbibl AND subtype:collection AND coll:${coll}`,
    fl: "*",
  };
  const {
    isLoading: isCollInfoLoading,
    data: collInfo,
    isError: isCollInfoError,
    error: collInfoError,
  } = useSolr(query);

  if (isCollInfoLoading) {
    return <THLSkeleton />;
  }
  if (!collInfo?.docs?.length || collInfo?.docs?.length < 1) {
    return <p>No Collection, {coll}, to display!</p>;
  }
  const colldoc = collInfo.docs[0];
  return (
    <div className="l-catalog-frame">
      <div className="summary">
        <h1>Collection Summary</h1>
        <CollInfo doc={colldoc} showEd={true} />
      </div>
    </div>
  );
}

export function CollInfo({ doc, showEd }) {
  const coll = doc.sigla_s;
  const query = {
    q: `type:tibbibl AND coll:${coll} AND subtype:edition`,
    fl: "*",
  };
  const {
    isLoading: isCollEdsLoading,
    data: collEds,
    isError: isCollEdsError,
    error: collEdsError,
  } = useSolr(query);

  if (isCollEdsLoading) {
    return <THLSkeleton />;
  }
  /*
  if (coll === "km") {
    console.log("km eds query", query);
    console.log("km eds", collEds);
  }*/
  let desc = Array.isArray(doc?.description_t) ? doc?.description_t[0] : doc?.description_t;
  if (typeof desc === 'string') {
    desc = desc.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
  }
  const cname = showEd ? "editions" : "list";
  const sorteddocs = collEds?.docs.sort((x, y) => {
    if (x.ed === y.ed) {
      return 0;
    }
    return x.ed > y.ed ? 1 : -1;
  });
  collEds.docs = sorteddocs;

  // console.log("Colleds", collEds);
  return (
    <div className={`collection ${cname}`}>
      <h2>{doc?.title_en}</h2>
      <HtmlCustom markup={desc} />
      <CollStats doc={doc} eds={collEds?.docs} />
      {showEd &&
        collEds &&
        collEds?.docs?.map((d, di) => {
          return <CollEd key={d.id} ed={d} coll={doc} />;
        })}
    </div>
  );
}

function CollStats({ doc, eds }) {
  const query = {
    q: `type:tibbibl AND coll:${doc.sigla_s}`,
    fl: "*",
    rows: "1",
    facet: "on",
    "facet.field": "subtype",
  };
  const {
    isLoading: isCollStatsLoading,
    data: collStats,
    isError: isCollStatsError,
    error: collStatsError,
  } = useSolr(query);

  if (isCollStatsLoading) {
    return <THLSkeleton />;
  }

  const subtypes = collStats?.facets?.subtype;
  if (!subtypes) {
    return null;
  }
  return <CatStats subtypes={subtypes} eds={eds} />;
}

function CollEd({ ed, coll }) {
  const query = {
    q: `type:tibbibl AND coll:${ed.coll} AND edsig:${ed.edsig}`,
    fl: "*",
    rows: "1",
    facet: "on",
    "facet.field": "subtype",
  };
  const {
    isLoading: isEdStatsLoading,
    data: edStats,
    isError: isEdStatsError,
    error: edStatsError,
  } = useSolr(query);
  if (isEdStatsLoading) {
    return <THLSkeleton />;
  }
  const subtypes = edStats?.facets?.subtype;

  // TODO: Normalize description so it doesn need the field name: discussion_history-of-the-edition_t
  const desc = ed["discussion_history-of-the-edition_t"];
  return (
    <div>
      <h2>{ed.ed}</h2>
      {desc && <HtmlCustom markup={desc} />}
      <CatStats subtypes={subtypes} />
      <p>
        <Link to={`/catalog/${ed?.coll}/${ed.edsig}`}>
          View the {ed.ed} edition
        </Link>
      </p>
    </div>
  );
}

function CatStats({ subtypes, eds }) {
 // Calculate volume range from among all editions
 const edvolcounts = eds?.map((ed, ei) => {
   let totalvols = '-1';
   if (ed?.count_volumes_ss) {
     totalvols = ed.count_volumes_ss.filter((ec, eci) => {return ec?.includes('total|') });
     totalvols = totalvols[0].replace('total|', '');
   }
   return totalvols * 1;
 }).filter((tv, tvi) => {return tv > 0 });
 let volctst = 'n/a';
 if (edvolcounts?.length === 1) {
   volctst = edvolcounts[0];
 } else if (edvolcounts?.length > 1) {
   volctst = Math.min(...edvolcounts) + " to " + Math.max(...edvolcounts);
 }

  // Calculate text range from among all editions
  const edtxtcounts = eds.map((ed, ei) => {
    let totaltxts = '-1';
    if (ed?.count_texts_ss) {
      // console.log("text counts", ed?.count_texts_ss);
      totaltxts = ed.count_texts_ss.filter((ec, eci) => {return ec?.includes('total|') });
      totaltxts = totaltxts[0]?.replace('total|', '');
    }
    if (totaltxts === '') { totaltxts = '-1'; }
    return totaltxts * 1;
  }).filter((tv, tvi) => {return tv > 0 });
  let txtctst = 'n/a';
  if (edtxtcounts?.length === 1) {
    txtctst = edtxtcounts[0];
  } else if (edtxtcounts?.length > 1) {
    txtctst = Math.min(...edtxtcounts) + " to " + Math.max(...edtxtcounts);
  }

  return (
    <>
      <ul className="mt-2">
        {eds && eds?.length > 0 && (
          <li>
            <strong>Editions: </strong> {subtypes.edition} (<EdList eds={eds} />
            ){" "}
            <span className="edsummary">
              — <Link to={`/catalog/${eds[0].coll}/eds`}>Summary</Link>
            </span>
          </li>
        )}
        <li>
          <strong>Volumes: </strong> {volctst}
        </li>
        <li>
          <strong>Texts: </strong> {txtctst}
        </li>
      </ul>
    </>
  );
}

function EdList({ eds }) {
  const edlinks = eds.map((ed, edind) => {
    const coll = ed?.coll;
    const edsig = ed?.edsig || "main";
    const shortTitle = ed?.ed;
    return (
      <li key={`${coll}-${edsig}-edlink-${edind}`} className="edlink">
        <Link to={`/catalog/${coll}/${edsig}`}>{shortTitle}</Link>
      </li>
    );
  });
  return <ul className="edlist">{edlinks}</ul>;
}
