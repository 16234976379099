import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {biblSectionTypes} from "./BiblRecord";
import {useBiblStore} from "../../hooks/useBiblStore";

export function BiblSectionNav({
                                   sections,
                                   selkey,
                                   setSelected
                               }) {

    const bibl = useBiblStore((state) => state.doc);

    // Bibl Record Sections Click Function
    const clickMe = (v) => {
        const el = v.target;
        setSelected(el.dataset.divkey);
        return false;
    };
    const allclass = selkey === "all" ? "selected" : "";
    const reader_base = window.location.pathname; // the base path to the current text being viewed

    // In Digital Text View, one can toggle with scans, if they exist
    let text_link = null;
    if (bibl?.hastext) {
        const slug = (bibl?.texttype == 'styled') ? 'text' : 'pages';
        text_link = <li><Link to={`${reader_base}/${slug}`}>Digital Text</Link></li>;
    } else if (bibl?.hasscans) {
        // If there are only scans and no digital text, we need to still add a link but to just scans
        text_link = <li><Link to={`${reader_base}/scans`}>Page Images</Link></li>;
    }

    return (
        <div className="c-bibl-nav">
            <h3>Sections</h3>
            <ul>
                <li key="pg-toc-key-all" className={allclass}>
                    <a data-divkey="all" onClick={clickMe} href="#">
                        All
                    </a>
                </li>
                {Object.keys(sections).map((kn, i) => {
                    const k = sections[kn];
                    const v = biblSectionTypes[k];
                    const myclass = k === selkey ? "selected" : "";
                    return (
                        <li key={`pg-toc-key-${i}`} className={myclass} id={`${k}-link`}>
                            <a data-divkey={k} onClick={clickMe} href="#">
                                {v}
                            </a>
                        </li>
                    );
                })}
                {text_link}
            </ul>
        </div>
    );
}
