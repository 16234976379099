import React from "react";
import "./App.css";
import MainRoutes from "./MainRoutes";
import { QueryClient, QueryClientProvider } from "react-query";

function App() {
  const queryClient = new QueryClient();

  return (
        <QueryClientProvider client={queryClient}>
          <MainRoutes />
        </QueryClientProvider>
  );
}

export default App;
