import React from "react";
import { format } from "date-fns";

export function BiblMetadata({ bibl }) {
  let dtcreate = "";
  if (bibl?.created_dt) {
    try {
      dtcreate = new Date(bibl.created_dt);
      dtcreate = format(dtcreate, "MMM d, yyyy");
    } catch (e) {}
  }
  let revdata = Array.isArray(bibl.revisions)
    ? bibl.revisions
    : [bibl.revisions];
  const revs = revdata.map((rev, ri) => {
    if (typeof rev === "undefined") {
      return null;
    }
    let revdate = "";
    if (rev?.date_dr) {
      revdate = Date.parse(rev.date_dr);
      revdate = format(revdate, "MMM d, yyyy");
    }
    const revagnt = rev?.persname_s || bibl?.resp_cataloguer_s || 'Unknown';
    const revdesc = (rev?.desc) ? `${rev.desc} (${revagnt}, ${revdate})` : `${revagnt}, ${revdate}`;
    return (
      <li key={`bibl-revision-${ri}`}>
        <label>{rev?.resp[0]}</label>
        <span>
          {revdesc}
        </span>
      </li>
    );
  });
  return (
    <div className="c-bibl_metadata">
      <h2>Metadata</h2>
      <ul className="list-unstyled">
        <li key="bibl-metadata-sysid">
          <label>ID</label>
          <span>{bibl.sysid_s}</span>
        </li>
        {bibl?.resp_cataloguer_s && (
          <li key={`bibl-metadata-cataloger-${bibl.resp_cataloguer_s}`}>
            <label>Cataloger</label>
            <span>{bibl.resp_cataloguer_s}</span>
          </li>
        )}
        {dtcreate && (
          <li key="bibl-metadata-date">
            <label>Date Cataloged</label>
            <span>{dtcreate}</span>
          </li>
        )}
        {revs}
      </ul>
    </div>
  );
}
