import React, { useEffect } from "react";
import { useBiblStore } from "../hooks/useBiblStore";
import { useBibl } from "../hooks/useBibl";
import { getResourceVal, parseResourceRange } from "./catalogUtils";

export function LookupEdBibl({ coll, ed }) {
  const setEdBibl = useBiblStore((state) => state.setEdBibl);
  const setColl = useBiblStore((state) => state.setColl);
  const edBiblId =
    !ed || ed === "main" || ed === "" ? `${coll}-bib` : `${coll}-${ed}-bib`;
  // Load the EdBibl
  let {
    isLoading: isBiblLoading,
    data: bibl,
    isError: isBiblError,
    error: biblError,
  } = useBibl(edBiblId);
  // console.log("ed bibl gotten", bibl);

  useEffect(() => {
    // Extract the range of texts digitized or scanned from the resource fields into two item arrays in Ed Bibl
    if (bibl) {
      bibl.textrange = parseResourceRange(bibl?.resource_digtexts_s);
      // console.log("Text range", bibl.textrange);
      bibl.styledtexts = parseResourceRange(bibl?.resource_digtexts_styled_s);
      bibl.scanrange = parseResourceRange(bibl?.resource_scans_s);
      bibl.has_sides = getResourceVal(bibl, "has_sides"); // Extract value of has_sides into boolean
      bibl.pagination_type = getResourceVal(bibl, "pagination");
      bibl.no_vols = bibl?.pagination_type === 'digpage';
    }
    setEdBibl(bibl);
    setColl(bibl?.coll);
  }, [bibl]);

  return null;
}
