import React, {useEffect, useState} from "react";
import {useSearchStore} from "../hooks/useSearchStore";
import {useSolr} from "../hooks/useSolr";
import {useCookies} from "react-cookie";
import THLSkeleton from "../common/THLSkeleton";
import {FeaturePager} from "../common/FeaturePager/FeaturePager";
import {ResultItem} from "./ResultItem";

/**
 * Performs the search based on the query and other parameters in the SearchStore and returns the results
 * which is a list of records that have Bibl IDs.
 * These are generally texts, but could be volumes, doxcats, etc.
 * The hits within a particular bibid item are combined into a single hitlist using json.facets
 * combining results into unlimited buckets based on bibid_s field.
 * The hitlist is displayed as a toggle div below the bibid records title.
 *
 * Other components used to create the results page are below:
 *      ResultItem
 *      HitSummary
 *      HitList
 *
 * As well as the function getHit(hit, page, qry)
 *
 * @param facets
 * @returns {JSX.Element}
 * @constructor
 */
export default function SearchResults({ facets= {type: "Record Type", coll: "Collection", edsig: "Edition"} }) {
    const search = useSearchStore();
    const qry = useSearchStore( (state) => state.query);
    const start = useSearchStore( (state) => state.start);
    const buckets = useSearchStore( (state) => state.buckets);
    const numPerPage = useSearchStore( (state) => state.numPerPage);
    const [showBuckets, setShowBuckets] = useState([]);
    const [hitBibls , setHitBibls] = useState([]);
    const qobj = {
        q: `text:${qry}`,
        fl: `*,[child limit=5000]`,
        /*fq: [`-(pg_trans_bo:${qry} AND -pg_bo:${qry})`],*/
        facet: true,
        "facet.field": Object.keys(facets),
        "json.facet": "{\"texthits\": {\"type\": \"terms\", \"field\": \"bibid_s\", \"limit\":-1 }}"
    };

    if (search?.hasFilters()) {
        // console.log("has filters");
        const newfq = [];
        for (const [key, value] of Object.entries(search.filters)) {
            if(value.length > 0) {
                newfq.push(key + ':(' + value.join(' OR ') + ')');
            }
        }
        qobj.fq = newfq;
    }

    const {
        isLoading: isSearchLoading,
        data: searchres,
        isError: isSearchError,
        error: searchError,
    } = useSolr(qobj);

    useEffect( () => {
        search.setSolrQuery(qobj);
        search.setFacetTypes(facets);
        search.setResults(searchres);
        if (!search?.facetChange) {
            search.setInitialFacets(searchres?.facets);
        }
    }, [searchres]);


    useEffect(() => {
        if(Array.isArray(buckets)) {
            const newbuckets = buckets.slice(start, start + numPerPage);
            setShowBuckets(newbuckets);
        }
    }, [start, buckets]);

    if (isSearchLoading) {
        return <THLSkeleton />;
    }

    if (isSearchError) {
        console.log("Search error", searchError);
    }

    return (
        <div id="srch-results">
            <h1>Results for Query “{search?.query.replace(/\"/g, '')}”</h1>
            {/* When there are results found */}
            { search?.numFound > 0 && (
                <>
                    <FeaturePager />
                    <ul className="results list-unstyled">
                        {showBuckets?.map((doc, di) => {
                            return <ResultItem bibid={doc?.val} count={doc?.count} key={['result', di]} />;
                        })}
                    </ul>
                </>
            )}

            {/* When there are no results */}
            { search?.numFound === 0 && ( <NoResults /> )}
        </div>
    )
}

function NoResults() {
    const query = useSearchStore( (state) => state?.query);
    return (
        <div className="no-results">
            <p>No results were found for your query: {query}</p>
        </div>
    )
}
