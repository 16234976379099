import React, {useEffect, useState} from "react";
import {useBiblStore} from "../hooks/useBiblStore";
import {
    getBiblLang,
    convertToSectionId,
    convertToSectionLabel
} from "../catalog/catalogUtils";
import {
    GoTriangleDown,
    GoTriangleRight,
    GoPrimitiveSquare,
} from "react-icons/all";
import {usePager} from "../hooks/usePager";
import {usePagerText} from "../hooks/usePagerText";
import $ from 'jquery';


export default function TextToc({ view }) {
    const bibl = useBiblStore((state) => state.doc);
    let toc_items = <p>No TOC available for this text.</p>;
    if (bibl?.sections?.length > 0) {
        toc_items = ['a', 'b', 'c'].map((x, i) => {
            return <TextTocSection key={`txts-${i}`} bibl={bibl} sid={x} view={view} />
        });
    }
    useEffect(() => {
        if (bibl?.sections?.length > 0) {
            toc_items = ['a', 'b', 'c'].map((x, i) => {
                return <TextTocSection key={`txts-${i}`} bibl={bibl} sid={x} view={view} />
            });
        }
    }, [bibl?.sections]);
    return (
        <div className="text-toc">
            {toc_items}
        </div>
    );
}


function TextTocSection({bibl, sid, view}) {
    const sections = bibl?.sections;
    if (!sections) { return null; }
    let label = "Body"
    if (sid == 'a') { label = "Front"; }
    if (sid == 'c') { label = "Back"; }
    const myid = `${bibl.id}-${sid}`;
    let chapters = sections?.filter((s) => {
        return s.parent_s === myid;
    });
    chapters = chapters.map((ch, i) => {
        return <TextTocChapter key={`ttoc-${myid}-${i}`} chapter={ch} sections={sections} view={view} />
    });
    return (
        <div id={`toc-${myid}`}>
            <h4>{label}</h4>
            <ul className="list-unstyled">{chapters}</ul>
        </div>
    );
}

function TextTocChapter({chapter, sections, view}) {
    const pg_pager = usePager();
    const text_pager = usePagerText();
    const pager = (view === 'pages') ? pg_pager : text_pager;
    const [selected, setSelected] = useState(false);
    const [open, setOpen] = useState(false);
    const lang = getBiblLang(chapter);

    const children = sections.filter((s) => {
        return s?.parent_s === chapter?.id;
    });

    let title  = (chapter[`title_chap_${lang}`]) ? chapter[`title_chap_${lang}`][0] : chapter['title'][0];
    if (!title || title === '') {
        title = chapter?.label_s;
    }

    useEffect(() => {
        // console.log("in use effect", pager?.subsection);
        if (pager?.section === chapter?.chapid_s || pager?.subsection === chapter?.chapid_s) {
            setOpen(true);
        }
        // Set to selected if visible
        if (chapter?.chapid_s && pager.visible.includes(chapter?.chapid_s)) {
            setSelected(true);
        } else {
            setSelected(false);
        }
        // Set to selected if child is visible (i.e. this id is a substring of some id in the list)
        pager.visible.forEach((s, i) => {
            if (s.indexOf(chapter?.chapid_s + '-') > -1) {
                setSelected(true);
            }
        });
    }, [pager]);

    let icon = (!children?.length) ?  <span className="icon-square"><GoPrimitiveSquare/></span> :
        (open) ? <GoTriangleDown /> : <GoTriangleRight />;

    const togglediv = () => {
        setOpen(!open);
    }
    const chaplabel = convertToSectionLabel(chapter?.chapid_s);

    const sectionUpdate = (chaplabel) => {
        const chpid = convertToSectionId(chaplabel);
        pager.updateVisible([]);
        // console.log("chapid in set section", chpid);
        pager.setSection(chapter);  // give the whole chapter
        $('.text-toc .selected').removeClass('selected');
        const elid = `s-${chpid}`;
        setTimeout(function() {
            const el = document.getElementById(elid);
            if (el) { el.scrollIntoView();}
        }, 300);
        setTimeout(function() {
            pager.updateVisible([chpid]);
        }, 800);
    }

    let selclass = (selected) ? ' selected' : '';
    return (
        <li id={`toc-${chapter?.chapid_s}`} className={`list-unstyled${selclass}`}>
            <a className="toc-icon" onClick={togglediv}>{icon}</a>
            <a className="link chapter" onClick={() => sectionUpdate(chaplabel)} >
                <span className="chapid text-capitalize">{chaplabel}.</span>
                <span className={lang}>{title}</span>
                {/* Pagination removed for now */}
                {false && chapter?.pagination_s && (<span className="chappage parens">{chapter?.pagination_s}</span>)}
            </a><> </>
            <span className="toc-pgn">({chapter?.pagination_s})</span>
            <div className={open ? 'open' : 'closed'}>
                {children?.length > 0 && open && (
                    <ul>
                        {children.map((ch, i) => {
                            return <TextTocChapter key={`ttoc-${chapter?.id}-${i}`} chapter={ch} sections={sections} />;
                        })}
                    </ul>
                )}
            </div>
        </li>
    )
}
