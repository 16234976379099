import React, {useEffect, useRef, useState} from "react";
import {useBiblStore} from "../hooks/useBiblStore";
import {Button, Form} from "react-bootstrap";
import {useSearchStore} from "../hooks/useSearchStore";
import {Link, Redirect, useRouteMatch} from "react-router-dom";
import axios from 'axios';
import jsonpAdapter from "../common/axios-jsonp";
import {useCookies} from "react-cookie";
import {IoArrowBack} from "react-icons/all";

export function NavSearch() {

    const search = useSearchStore();
    const [disabled, setDisabled] = useState(false);
    // When searchstr state is changed, the search is initiated
    const [searchStr, setSearchStr] = useState('');
    const [doRedirect, setRedirect] = useState(false);  // state to trigger redirect to search page
    const [convertWylie, setConvertWylie] = useState(false);

    let onSearchPage = useRouteMatch("/catalog/search");

    const seachboxRef = useRef(); // Reference to html text input where search string is entered
    const wyliecbRef = useRef();
    const cookie_names = [
        'thlcat_convwyl',
        'thlcat_searchactive',
        'thlcat_searchquery',
    ];
    const [cookies, setCookie, removeCookie] = useCookies(cookie_names);

    const getWylie = (wy, inputbox=false) => {
        if(wy?.length === 0) {
            return; // Return if wy is empty
        }  else if (!convertWylie) {
            setSearchStr(wy);
            return;
        }
        // TODO: Implement a site setting to turn off conversion of Wylie
        // Check if already Tibetan
        if (wy.trim().charCodeAt(0) > 3839) {
            if (!inputbox) { setSearchStr(wy); }
            return;
        }

        // console.log(`Wylie is [${wy}]`);

        // Make call for wylie
        let request = {
            url: process.env.REACT_APP_WYLIE_URL,
            adapter: jsonpAdapter,
            dataType: 'jsonp',
            callbackParamName: "callback",
            params: { wy: wy, callback: 'wylie' }
        };

        axios.request(request)
             .then( (res) => {
                 const wyret = res.data.wy;
                 let bo = res.data.bo;
                 if (/(line|unexpected|character)/.test(bo.toLowerCase())) {
                     setSearchStr(wy)  // If error converting to wylie, just search on string
                 } else {
                     if (!wy.match(/\s$/) && bo.charCodeAt(bo.length - 1) === 3851) {  // 3851 == tsek
                         bo = bo.substring(0, bo.length - 1);
                     }
                     if (inputbox) {
                         // If inputbox is true, set value of input box to converted Tibetan unicode
                         seachboxRef.current.value = bo;
                     } else {
                         // Otherwise initiate the search. See useEffect below.
                         setSearchStr(bo);
                     }
                 }
             })
            .catch((err) => {
                console.log("error", err);
            });
    };

    // If query already defined and searchbox exists, fill in search box with current query
    useEffect(() => {
        if (search?.query && seachboxRef?.current) {
            const sval = search?.query.replace(/"/g, '');
            seachboxRef.current.value = sval;
            setCookie('thlcat_searchquery', sval);
            setCookie('thlcat_searchactive', true);
        }
    }, [search, seachboxRef]);

    useEffect(() => {
        if (cookies?.thlcat_searchquery) {
            search.setQuery(cookies?.thlcat_searchquery);
            setCookie('thlcat_searchactive', true);
        }
    }, [cookies?.thlcat_searchquery]);

    useEffect(() => {
        if (cookies?.thlcat_convwyl) {
            setConvertWylie((cookies.thlcat_convwyl === 'true'));
        }
    }, []);

    // When the search string state is changed, initiate the search, redirect to search page if not on it
    useEffect(() => {
        // console.log(`Search string changed: [${searchstr}]`);
        if (searchStr?.trim().length > 0) {
            search.setQuery(searchStr);
            let doRedirect = !onSearchPage;
            setRedirect(doRedirect);
        }
    }, [searchStr]);

    const setInputVal = (e) => {
        const sval = seachboxRef?.current?.value.trim();
        getWylie(sval, true);
    }

    const submitSearch = (e) => {
        e.preventDefault();
        getWylie(seachboxRef?.current?.value);
        if (!onSearchPage) {
            setRedirect(true);
        }
    }

    const clearCheck = (e) => {
        if (search.isActive) {
            const sval = seachboxRef?.current?.value;
            if (sval == '') {
                setRedirect(false);
                seachboxRef.current.value = '';
                search.reset();
            }
        }
    }

    // If already on search result page, set doRedirect to false
    if (onSearchPage && doRedirect) {
        setRedirect(false);
    }

    // Redirects to the Search result page if not already on it
    if (!onSearchPage && doRedirect) {
        return <Redirect push to={'/catalog/search'} />;
    }

    const wylieClick = () => {
        setConvertWylie(wyliecbRef.current.checked);
        setCookie('thlcat_convwyl', wyliecbRef.current.checked);
    };

    const onSearchClass = (onSearchPage && search?.showing) ? ' search-page' : '';

    return (
        <Form className="nav-search-form d-flex p-2" onSubmit={submitSearch} >
            <Form.Check id="wyl-cb" className="f-wyl-cb" >
                <Form.Check.Label>Convert Wylie</Form.Check.Label>
                <Form.Check.Input
                    ref={wyliecbRef}
                    type={'checkbox'}
                    onChange={wylieClick}
                    checked={convertWylie}
                    title="Automatically convert Wylie to Unicode Tibetan upon submission"
                />
            </Form.Check>

            <div className={`search-and-back${onSearchClass}`}>
                <Form.Control
                    type="search"
                    placeholder="Search & Explore!"
                    className="me-2 cat-search"
                    aria-label="Search"
                    ref={seachboxRef}
                    onChange={clearCheck}
                    onBlur={setInputVal}
                    disabled={disabled}
                />
                {search.isActive && (
                        <div className={`back`}><Link to="/catalog/search" title="Back to results page">Back to search results</Link></div>
                    )
                }
            </div>
            <Button variant="outline-success"
                    onClick={submitSearch}
                    className="submit-btn"
                    disabled={disabled}
            >Search</Button>
        </Form>
    );
}
