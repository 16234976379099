import React, { useEffect, useState } from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import "./css/CatalogFrame.scss";
import BiblRecord from "./biblrecord/BiblRecord";
import { DoxRecordDisplay } from "./DoxRecord";
import SplitPane, { Pane } from "react-split-pane";
import TextReader from "../reader/TextReader";
import THLSkeleton from "../common/THLSkeleton";
import { useBiblStore } from "../hooks/useBiblStore";
import { useBibl } from "../hooks/useBibl";
import {inList, inRange, zfill} from "./catalogUtils";
import { LookupEdBibl } from "./LookupEdBibl";
import DoxVolBiblDisplay from "./DoxVolDisplay";
import {useSearchStore} from "../hooks/useSearchStore";
import SearchResults from "../search/SearchResults";
import SearchFacets from "../search/SearchFacets";
import {useSolr} from "../hooks/useSolr";
import TablesOfContent from "./TablesOfContent";

const CFDBUG = false;

/**
 * CatalogFrame displays all parts of the catalog. It is a split pane with the content on the left and
 * a TOC on the right. The TOC is the catalog TOC when looking at bibl records or a text's sections TOC
 * when looking at a text. There is a variable below for "biblcontent" which is the content for the main,
 * content section of the frame. There are two tabs for the catalog or texts TOC on the right.
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function CatalogFrame() {
  const setDoc = useBiblStore(state => state.setDoc); // Bibl State set function
  const setDynamicSections = useBiblStore(state => state.setDynamicSections);
  const collnm = useBiblStore(state => state.collname);
  const edBibl = useBiblStore(state => state.edBibl);
  const catSearch = useSearchStore();
  const { pathname } = useLocation();

  const [tocType, setTocType ] = useState('default');

  let params = useParams(); // Get URL Params

  if (CFDBUG) {
    console.log("params", params);
  }

  let { coll, ed, vol, txtnum, dox, view, page, sid } = params;
  if (txtnum === 'dox') {
    txtnum = '';
    dox = view;
    view = 'dox';
  }

  // console.log(edBibl);
  /*console.log(
    `coll: ${coll}, ed: ${ed}, vol: ${vol}, textnum: ${txtnum}, dox: ${dox}`
  );*/

  const [dragged, setDragged] = useState(false); // Drag state for split screen view

  // Calculate the bibl or dox ID
  let biblpart = vol && !(view === 'pages')
    ? "-v" + zfill(vol, 3)
    : txtnum
    ? "-" + zfill(txtnum, 4)
    : "";
  if (ed && ed !== "" && ed !== "main") {
    biblpart = `-${ed}${biblpart}`;
  }
  let biblId = `${coll}${biblpart}-bib`;
  if (dox) {
    biblId = dox.includes("-dox") ? dox : `${dox}-dox`;
  }

  if (CFDBUG) {
    console.log("bibl part: ", biblpart);
    console.log("bibl id", biblId);
  }

  // Load the Bibl
  let {
    isLoading: isBiblLoading,
    data: bibl,
    isError: isBiblError,
    error: biblError,
  } = useBibl(biblId);

  // Query for text section list for dynamic TOC from styled texts.
  const query = {
    q: `type:textsection AND bibid_s:${biblId}`,
    rows: 5000,
    fl: 'id,section_num_s,section_title_s',
    sort: 'section_sort_s asc',
  }
  const {
    isLoading: sectListLoading,
    data: sectionList,
    isError: isSectListError,
    error: sectListError,
  } = useSolr(query);

  useEffect(() => {
    setDoc(bibl);
  }, [bibl, edBibl]);

  useEffect(() => {
    // console.log("section list", sectionList);
    if (!bibl?.toc && !bibl?.dynamicsections) {
      if (sectionList?.numFound > 0) {
        // console.log("Section list", sectionList);
        const ttoc = sectionList.docs.map((d, di) => {
          return d?.section_num_s;
        });
        setDynamicSections(sectionList.docs, ttoc);
      }
    }
  }, [sectionList]);

  useEffect(() => {
    let newType = (catSearch?.isNew) ? 'facets' : 'default';
    setTocType(newType);
  }, [catSearch?.isNew]);

  if (isBiblLoading) {
    return <THLSkeleton />;
  }
  if (isBiblError) {
    console.error(`Error loading bibl: ${biblId}`, biblError);
    return (
      <div className="error">
        There was a problem loading the bibl for {biblId}
      </div>
    );
  }


  if (!pathname.includes('search') && catSearch?.isNew) {
    catSearch.setIsNew(false);
  }

  if (bibl) {
    // Whether a text has a digital version can be set in the edition bibl or individually by text
    // See tibbibl2solr.xsl for how it is set in solr record by text.
    // If there is a type attribute, the resource_digital_text_s will look like (e.g. if type="styled"):
    //           styled|lccw/texts/0001/lccw-0001-text.xml
    // Now, also checks edBibl for resource_digtexts_s and resource_digtexts_styled_s fields
    // See LookupEdBibl.js these contain arrays of text numbers that have digitaled texts and if they're styled
    let tnum_i = bibl?.text_num * 1;
    bibl.hastext = bibl?.resource_digital_text_s || (edBibl?.textrange && edBibl?.textrange?.includes(tnum_i));
    if (bibl.hastext) {
      bibl.texttype = 'pages';
      if (edBibl?.styledtexts && (edBibl?.styledtexts === 'all' || edBibl?.styledtexts?.includes(tnum_i))) {
        bibl.texttype = 'styled';
      }
    }
    bibl.hasscans = inList(bibl?.text_num, edBibl?.scanrange);
  }

  if (CFDBUG) {
    console.log("Catalog frame bibl", bibl);
  }

  if (!edBibl || edBibl?.coll !== coll || edBibl?.edsig !== ed) {
    // Set the Edition Information if not set. Once set, this will get passed over on react refresh (useEffect).
    // console.log("looking up edbibl: ", edBibl, coll, ed);
    return <LookupEdBibl coll={coll} ed={ed} />;
  }
  // Determine type of content to display
  let framecontent = null;


  // Doxographical Category Display (either by volume or genre)
  if (bibl?.type === "dox" || bibl?.type === "doxcat") {
    if (CFDBUG) {
      console.log("Showing dox record");
    }
    if (bibl?.subtype === "volume") {
      framecontent = <DoxVolBiblDisplay />;
    } else {
      framecontent = <DoxRecordDisplay />;
    }

  // Text Display either digital text or scans
  } else if (["text", "pages", "scans"].includes(view)) {
    if (CFDBUG) {
      console.log("Showing Text Reader");
    }
    catSearch.isNew = false;
    framecontent = <TextReader viewMode={view} dragged={dragged} vol={vol} page={page} sid={sid}/>;

  // Default is the current Bibl Record
  } else {
    if (CFDBUG) {
      console.log("Showing bibl record");
    }
    framecontent = <BiblRecord />;
  }

  // Determine whether to show toc tab
  const hasSections = bibl?.sections?.length > 0;

  const startDrag = () => {
    setDragged(true);
  };
  const endDrag = () => {
    setDragged(false);
  };

  const edlink = `/catalog/${coll}/${ed}`;
  const edname = (edBibl?.edsig !== 'main') ? edBibl?.ed : '';
  const edhead = (edlink !== pathname) ? <Link
      to={`/catalog/${coll}/${ed}`} className="edlink" title="Back to edition home page">{edname} {collnm}</Link> :
      `${edname} ${collnm}`;

  return (
    <>
      <SplitPane
        split="vertical"
        className="l-catalog-frame"
        defaultSize="60vw"
        minSize={10}
        onDragStarted={startDrag}
        onDragFinished={endDrag}
      >
        <Pane className="l-frame-content">
          <div className="l-frame-wrapper">
            {!catSearch?.isNew && framecontent}
            {catSearch?.isNew && (<SearchResults />)}
          </div>
        </Pane>
        <Pane className="l-frame-toc">
          <div className="l-frame-wrapper">
            <h1 className="h6 mt-1 font-italic">{edhead}</h1>
            {tocType === 'default' && <TablesOfContent biblId={biblId} biblpassed={bibl} view={view}
                                        hasSections={hasSections} />}
            {tocType === 'facets' && <SearchFacets />}
          </div>
        </Pane>
      </SplitPane>
    </>
  );
}
