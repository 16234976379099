import React, { useEffect } from "react";
import { BiblAgents, getBiblAgents } from "./BiblAgents";
import { BiblColophon } from "./BiblColophon";
import { getFieldsByString } from "../catalogUtils";

export function BiblProvenance({ bibl }) {
  return (
    <div className="c-bibl_provenance">
      <h2>Provenance</h2>
      <ul className="list-unstyled">
        <BiblAgents bibl={bibl} />
        <BiblColophon bibl={bibl} />
      </ul>
    </div>
  );
}
