import {
    defaultCollNames,
    getBoField,
    getCollNames, getDefaultField,
    getFieldsByString,
    getLangTypedField, getSolrVal, hasField,
    solrFieldValue,
} from "../catalogUtils";
import React, {useState} from "react";
import {Table} from "react-bootstrap";
import {useBibl} from "../../hooks/useBibl";
import {Link} from "react-router-dom";

export function BiblCorresp({ bibl }) {
  // console.log(bibl);
  if (!bibl || !bibl?.corresps) { return null; }
  // Return the id info div
  return (
    <div className="c-bibl_corresp">
      <h2>Parallel Editions</h2>
      <Table striped bordered hover className="parallel-texts">
        <thead>
          <tr>
            <th>Edition Name</th>
            <th>Text #</th>
            <th>Volume</th>
            <th># in Vol.</th>
            <th>Pagination</th>
          </tr>
        </thead>
        <tbody>
          { bibl?.corresps?.map((cr, cri) => {
              if (cr?.filename_s?.length > 0) {
                  return <ParallelEdition eddata={cr} lang={bibl?.langcode} key={`paraed-${cri}`} />;
              } else {
                  return <TextRow edname={cr?.ed_title_s}
                                  sig={cr?.edsig}
                                  tnum={cr?.text_num}
                                  lang="en"
                                  link={false}
                                  key={`parabibl-${cri}`}
                  />;
              }

          })}
        </tbody>
      </Table>
    </div>
  );
}


function ParallelEdition({eddata, lang}) {
    //console.log("eddata", eddata);
    const bibl_id = eddata?.filename_s?.replace('.xml', '');
    const {
        isLoading: isBiblLoading,
        data: pbibl,
        isError: isBiblError,
        error: biblError,
    } = useBibl(bibl_id);
    if (isBiblLoading ) {return null;}
    if (isBiblError) {
        console.log(biblError);
        return "error";
    }
    // console.log("Loaded bibl", pbibl);
    const nfl = `ed_alt_title_${lang}`;
    const ednameLang =  hasField(eddata, nfl) ? solrFieldValue(eddata[nfl]) : false;
    let ednameEng = eddata?.ed_title_s || false;
    let ednm = <span className="en">No edition title</span>;
    if (ednameLang) {
        ednm = (<>
            <span className={lang}>{ednameLang}</span>
            {ednameLang && (<span className="en"> ({ednameEng})</span>)}
        </>)
    } else if (ednameEng) {
        ednm = <span className="en">{ednameEng}</span>;
    }
    let vnum = pbibl?.vol_num;
    let vlet = '';
    const vlngfld = `vol_${lang}_s`;
    if(hasField(pbibl, vlngfld)) {
        vlet = pbibl[vlngfld];
    }
    const pgnum = pbibl?.pg_start_text_s + '-' + pbibl?.pg_end_text_s;
    const link = `/catalog/${pbibl?.coll}/${pbibl?.edsig}/${pbibl?.text_num}`;
    return <TextRow edname={ednm}
                    lang={lang}
                    sig={pbibl?.edsig}
                    tnum={pbibl?.text_num}
                    vnum={vnum}
                    vlet={vlet}
                    vtxtnum={pbibl?.vol_txtnum_i}
                    page={pgnum}
                    link={link}
    /> ;
}

function TextRow({ edname, sig, tnum,  lang='', vnum=null, vlet=null, vtxtnum=null,
                     page=null, link=false}) {
    return (
        <tr>
            <td>{edname}</td>
            {link && (<td><Link to={link}><span className="text-capitalize">{sig}</span>.{tnum}</Link></td>)}
            {!link && (<td><span className="text-capitalize">{sig}</span>.{tnum}</td> )}
            {vnum && (<td>{vnum} (<span className={lang}>{vlet}</span>)</td>)}
            {!vnum && (<td></td>)}
            <td>{vtxtnum}</td>
            <td>{page}</td>
        </tr>
    );
}
