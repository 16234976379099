import React, {StrictMode} from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import "./css/index.css";
import "./css/custom.scss";

const rootEl = document.getElementById("root");
const root = createRoot(rootEl);
root.render(
    <StrictMode>
        <App tab="home" />
    </StrictMode>
);

