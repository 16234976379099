import {useSolr} from "../hooks/useSolr";
import THLSkeleton from "../common/THLSkeleton";
import React, {useEffect, useState} from "react";
import {GoPrimitiveSquare, GoTriangleDown, GoTriangleRight} from "react-icons/all";
import {splitNumberedTitle} from "../catalog/catalogUtils";
import {Link} from "react-router-dom";
import {usePagerText} from "../hooks/usePagerText";

/**
 * This component creates a TOC built dynamically from a text's indexed sections or solr docs with type=textsection.
 *
 * @param bibl {Object} The JSON object that represents this text's SOLR document.
 * @param level {number} Optional level to start at. Defaults to 1. (I don't think this is used. May be unnecessary.)
 *
 * @returns {JSX.Element} A full TOC tree rendered in HTML
 * @constructor
 */
export function DynamicTextToc({bibl, level= 1}) {
    // Load all indexed text sections of the current level
    const bibid = bibl?.bibid_s || 'loading'; // random string to search on if no bibl is given
    const query = {
        q:`bibid_s:${bibid}`,
        fq:['type:textsection', `section_level_i:${level}`],
        fl: '*,content:[value v=""],content_mu:[value v=""]', // Do not retrieve content fields for speed concerns
        sort: 'section_sort_s asc',
        rows: 1000
    };
    let {
        isLoading: isSectionsLoading,
        data: sections,
        isError: isSectionsError,
        error: sectionsError,
    } = useSolr(query);

    if (isSectionsLoading) { return <THLSkeleton/>; }
    if (isSectionsError) { return <p>Error!</p> ;}

    const items = sections?.docs; // all sections

    const nosections = !sections?.docs || sections.docs.length === 0;

    // console.log(items);
    // Front
    const frontsects = items.filter((dts, dtsi) => {
        return dts.text_part_s === 'front';
    }).map((doc, dn) => {
        return <DynamicTextSection key={`dts-${dn}`} doc={doc}/>;
    });

    // Body
    const bodysects = items.filter((dts, dtsi) => {
        return dts.text_part_s === 'body';
    }).map((doc, dn) => {
        return <DynamicTextSection key={`dts-${dn}`} doc={doc}/>;
    });;

    // Back
    const backsects = items.filter((dts, dtsi) => {
        return dts.text_part_s === 'back';
    }).map((doc, dn) => {
        return <DynamicTextSection key={`dts-${dn}`} doc={doc}/>;
    });
    ;
    // console.log("fs", frontsects);
    // TODO: Create an true/false option for breaking TOC into front, body, back or not.
    return (
        <>
            {frontsects?.length > 0 && (
                <div className="sections front">
                    <h3>Front</h3>
                    <ul className="list-unstyled ml-3">
                        {frontsects}
                    </ul>
                </div>
            )}

            {bodysects?.length > 0 && (
                <div className="sections body">
                    <h3>Body</h3>
                    <ul className="list-unstyled ml-3">
                        {bodysects}
                    </ul>
                </div>
            )}

            {backsects?.length > 0 && (
                <div className="sections back">
                    <h3>Back</h3>
                    <ul className="list-unstyled ml-3">
                        {backsects}
                    </ul>
                </div>
            )}
        </>
    );
}

/**
 * Dynamic Text Section is one item in a list of sections. Tt is dynamic because it takes a "textsection" solr doc.
 * This is the actual section of a structure text, which also has its particular information.
 * This component queries for the same type of documents for any children this item has and then displays the item
 * Splitting the header into number and title and displaying a toggle option to open if it has children.
 *
 * @param doc {Object} The JSON object representing this text section (type=textsection).
 * @returns {JSX.Element} A rendered leaf (li) in the HTML tree with toggle to open if it has children.
 * @constructor
 */
function DynamicTextSection({doc}) {
    const [open, setOpen] = useState(false);
    const [childList, setChildList] = useState(null);
    const pager = usePagerText();

    // console.log(doc);
    const sid = doc?.section_num_s;

    // Get child information
    let children = doc?.children_ss;
    const qstr = (children) ? `section_num_s:(${children.join(' ')})` : "section_num_s:000";
    const query = {
        q:qstr,
        fq:[`bibid_s:${doc.bibid_s}`],
        sort: 'section_num_s asc',
        rows: 1000
    };
    let {
        isLoading: isSectionsLoading,
        data: child_sections,
        isError: isSectionsError,
        error: sectionsError,
    } = useSolr(query);

    // Determine Toggling
    let icon = (!children?.length) ?  <GoPrimitiveSquare className="end-leaf"/> :
        (open) ? <GoTriangleDown /> : <GoTriangleRight />;
    const togglediv = () => {
        setOpen(!open);
    }

    // Parse Section Label (split number from title into spans)
    const [sectnum, sectitle] = splitNumberedTitle(doc.section_title_s);

    // Deal with parents and create their child divs
    let myclass ="single";

    // Only build child list when section is opened
    useEffect(() => {
        // console.log("Open is: ", open, children?.length);
        if (open && children?.length > 0) {
            myclass = "parent";
            const newChildList = (
                <div className={open ? 'open' : 'closed'}>
                    <ul>
                        {children.map((ch, i) => {
                            if (child_sections?.docs?.length > 0) {
                                let me = child_sections.docs.filter((d, di) => {
                                    return d?.section_num_s === ch;
                                });
                                me = (me?.length > 0) ? me[0] : false;
                                if (me) {
                                    return <DynamicTextSection key={`dts-${me?.id}`} doc={me}/>;
                                }
                            }
                            return <li key={`dts-${Date.now()}-${Math.random()}`}>{i} : {ch}</li>;
                        })}
                    </ul>
                </div>
            );
            setChildList(newChildList);
        } else {
            setChildList(<div className="closed"></div>);
        }
    }, [open]);

    myclass +=  " lvl-" + doc?.section_level_i;

    // Function to set the section ID before going to text reader so that it loads that section
    // TODO: Need to come up with a way to us path to go to section so it can be bookmarked
    const setSid = (e) => {
        //console.log(sid);
        pager.setSection(sid);
    }

    // Return the Dynamic list item for this section
    const linkpath = `/catalog/${doc.coll}/${doc.edsig}/${doc.text_num}/text`; ///section/${sid}
    return (
        <li key={`dts-${doc?.id}`} id={`toc-${doc?.id}`} className={myclass}>
            <a className="toc-icon" onClick={togglediv}>{icon}</a>
            <span className="num en">{sectnum}</span><> </>
            <Link to={linkpath} className="link chapter" data-sectid={sid} onMouseDown={setSid}>
                <span className="title bo">{sectitle}</span>
            </Link>
            {childList}
        </li>
    )
}
