import { nextPage, pageStrToObj, prevPage } from "../catalog/catalogUtils";
import { Button, Form, FormControl } from "react-bootstrap";
import { HiChevronDoubleLeft, HiChevronDoubleRight } from "react-icons/hi";
import React, { useEffect, useRef, useState } from "react";
import { usePager } from "../hooks/usePager";
import { useBiblStore } from "../hooks/useBiblStore";
import {usePagerText} from "../hooks/usePagerText";

export function TextReaderPager({ view }) {
  const edBibl = useBiblStore((state) => state?.edBibl);
  const basic_pager = usePager();
  const text_pager = usePagerText();
  const pager = (view === 'text') ? text_pager : basic_pager;
  const [vol, setVol] = useState(pager?.vol);
  const [page, setPage] = useState(pager?.page);
  const [side, setSide] = useState(pager?.side);

  const volref = useRef();
  const pageref = useRef();
  const sideref = useRef();

  // console.log('edbibl', edBibl);
  // console.log("view", view);

  useEffect(() => {
    if (!edBibl.no_vols && pager?.vol) {
      setVol(pager.vol);
    }
    if (pager?.page) {
        setPage(pager.page);
    }
    if (edBibl.has_sides && pager?.side) {
      setSide(pager.side);
    }
  }, []);

  // UseEffect: when pager page changes, set controls to match. Use visible to determine scrolling
  useEffect(() => {
    setVol(pager.vol);
    setPage(pager.page);
    if (edBibl.has_sides) {
      setSide(pager.side);
    }

    // If there is a visible stack in pager, set controls to top page in stack
    if (pager?.visible?.length > 0) {
      const toppg = pageStrToObj(pager?.visible[0]);
      if(!edBibl?.no_vols) {
        setVol(toppg.vol);
      }
      if (toppg.page) {
        setPage(toppg.page);
      }
      if (edBibl.has_sides) {
        setSide(toppg.side);
      }
    }
  }, [pager]);

  useEffect(() => {
    if (!edBibl?.no_vols && volref?.current) {
      volref.current.value = vol;
    }
    if (pageref?.current) {
      pageref.current.value = page;
    }
    if (sideref?.current) {
      sideref.current.value = side;
    }
  }, [vol, page, side])

  // Previous and Next functions for Buttons
  const prevpg = () => {
    let volv = (!edBibl?.no_vols) ? volref.current.value : false;
    let pgv = pageref.current.value;
    let sdv = edBibl.has_sides ? sideref.current.value : false;
    if (edBibl.has_sides) {
      if (sdv === "a") {
        sdv = "b";
        pgv = pgv * 1 - 1;
      } else {
        sdv = "a";
      }
    } else {
      pgv = pgv * 1 - 1;
    }

    if(pager?.min > pgv) { pgv = pager.min; }
    if(pager?.max < pgv) { pgv = pager.max; }

    pager.setPage(volv, pgv, sdv);
  };

  const nextpg = () => {
    let volv = (!edBibl?.no_vols) ? volref.current.value : false;
    let pgv = pageref.current.value;
    let sdv = edBibl.has_sides ? sideref.current.value : false;
    if (edBibl.has_sides) {
      if (sdv === "b") {
        sdv = "a";
        pgv = pgv * 1 + 1;
      } else {
        sdv = "b";
      }
    } else {
      pgv = pgv * 1 + 1;
    }

    if(pager?.min > pgv) { pgv = pager.min; }
    if(pager?.max < pgv) { pgv = pager.max; }

    // console.log('max is', pager?.max, pgv);
    // console.log("Next page", volv, pgv, sdv);
    pager.setPage(volv, pgv, sdv);
  };

  const updatepg = (e) => {
    const ftype = e.target.dataset.type;
    let fval = e.target.value;
    if (fval === "") return;
    // Validation and Processing
    if (!edBibl?.no_vols && ftype === "volume") {
      fval = fval * 1;
      if (isNaN(fval) || fval < 1 || fval > 500) {
        volref.current.value = pager?.vol;
        return; // return on any problem
      }
    } else if (ftype === "page") {
      fval = fval * 1;
      if (isNaN(fval) || fval < 1) {
        pageref.current.value = pager?.page;
        return;
      }
    } else if (edBibl.has_sides && ftype === "side") {
      if (!"ab".includes(fval)) {
        sideref.current.value = pager?.side;
        return;
      }
    } else {
      console.log("Unknown field type in TextReaderPager.js");
      return;
    }

    // Entry if valid so change page
    const sdval = edBibl.has_sides ? sideref.current.value : false;
    pager.setPage(volref?.current?.value, pageref.current.value, sdval);
  };

  if ((!vol || vol === -1) && (!page || page === -1)) {
    // console.log("no page or vol in pager turner. Hiding...");
    return <div className="text-pager no-pages font-italic mt-1">Structured text without pages</div>;
  }

  const volclass = (edBibl.no_vols) ? ' hidden' : '';

  return (
    <div className="text-pager">
      <Button variant="link" className="prevbtn" size="sm" onClick={prevpg}>
        <HiChevronDoubleLeft title="Previous Page" />
      </Button>
      <Form.Group className={`pginput${volclass}`}>
          <Form.Label id="vollabel">Volume</Form.Label>
          <FormControl
              ref={volref}
              placeholder="Volume"
              aria-label="Volume"
              aria-describedby="vollabel"
              data-type="volume"
              value={vol}
              onChange={updatepg} />
      </Form.Group>
      <Form.Group className="pginput">
        <Form.Label id="pglabel">{edBibl?.pagination_type === 'digpage' && ("Digital ")}Page </Form.Label>
        <FormControl
          ref={pageref}
          placeholder="Page"
          aria-label="Page"
          aria-describedby="pglabel"
          data-type="page"
          value={page}
          type="test"
          onChange={updatepg}
        />
      </Form.Group>
      {edBibl?.has_sides && (
        <Form.Group className="pginput">
          <Form.Label id="sdlabel">Side </Form.Label>
          <FormControl
            ref={sideref}
            placeholder="Side"
            aria-label="Side"
            aria-describedby="sdlabel"
            data-type="side"
            value={side}
            onChange={updatepg}
          />
        </Form.Group>
      )}
      <Button variant="link" size="sm" className="nextbtn" onClick={nextpg}>
        <HiChevronDoubleRight title="Next Page" />
      </Button>
    </div>
  );
}
