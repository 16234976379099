import { useCatalog } from "../hooks/useCatalog";
import {
  getBiblLang,
  getMainTitle,
  getPagination,
  getTitleByLang,
} from "../catalog/catalogUtils";
import { Col, Container, Row } from "react-bootstrap";
import React, { useEffect, useRef } from "react";
import { TextReaderNav } from "./TextReaderNav";
import { useBiblStore } from "../hooks/useBiblStore";
import {usePager} from "../hooks/usePager";

export function TextReaderHeader({ view, setView }) {
  const bibl = useBiblStore((state) => state.doc);
  const edBibl = useBiblStore((state) => state.edBibl);
  const collnm = useBiblStore((state) => state.collname);
  const lang = getBiblLang(edBibl);

  //console.log("bibl", bibl);

  const pagination = bibl ? getPagination(bibl) : "";
  let title = bibl ? getMainTitle(bibl) : "";
  const tlang = title.lang;
  title = title ? title.idpref + title.title : "";
  // console.log("edbibil", edBibl);
  const edname = (edBibl.edsig !== 'main') ? edBibl?.ed : null;

  return (
    <Container id="c-text-reader_head">
      <Row id="c-text-reader_info">
        <Col md={4} className="collname">
          <span className="coll title text-wrap">{edname} {collnm}</span>
        </Col>
        <Col md={6} className="texttitle d-flex">
          <span className="id">{bibl?.display_id}</span><>: </>
          <span className={`title ${tlang} inline-block text-nowrap text-truncate`}>
            {title}
          </span>
        </Col>
        <Col md={2} className="pagination">
          <span className="location text-nowrap">{pagination}</span>
        </Col>
      </Row>
      <TextReaderNav view={view} setView={setView} />
    </Container>
  );
}
