import React, {useEffect, useRef, useState} from 'react';
import NumericInput from 'react-numeric-input';
import './FeaturePager.scss';
import {useSearchStore} from "../../hooks/useSearchStore";

export function FeaturePager(props) {
    const search = useSearchStore();
    const numPerPage = useSearchStore((store) => store.numPerPage);
    const [page, setPage] = useState(0); // Pages of buckets == texts\
    let maxPage = (search?.numBuckets) ? Math.floor(search?.numBuckets / numPerPage) + 1: 1;
    let start = search.start;

    let input_size = 3;
    if (maxPage > 99) {
        input_size = maxPage > 999 ? 5 : 4;
    }
    const position = props?.position ? ' ' + props.position : '';
    const classname = props?.className ? ' ' + props.className : '';

    useEffect(() => {
        if (numPerPage > 0) {
            if (numPerPage > search?.numBuckets || search?.start < numPerPage) {
                setPage(0);
            } else {
                setPage(Math.floor(search.start / numPerPage));
            }
            maxPage = (search?.numBuckets) ? Math.floor(search?.numBuckets / numPerPage) + 1: 1;
        }
    }, [numPerPage]);

    useEffect(() => {
        // console.log("in use effect", page, search?.start);
        if (page > -1 && search.start > -1) {
            search.setStart(page * numPerPage);
            //console.log("page is", page);
        }
    }, [page]);

    const calcPage = (amt) => {
        // console.log("in calcpage", amt);
        if (amt === 'max') {
            setPage(maxPage - 1);
        } else if (amt * 1 === 1 && page < maxPage - 1) {
            setPage(page + 1);
        } else if (amt * 1 === -1 && page > 0) {
            setPage(page - 1);
        }
    };

    const setPerPage = (pp) => {
        if (!isNaN(pp) && pp > 0 && pp !== numPerPage) {
            search.setNumPerPage(pp);
        }
    }

    return (
        <div className={`c-featurePager__container${position}${classname}`}>
            <div className="c-featurePager__resultSummary">
                <span className="total">{search?.numFound}</span> hits
                in <span className="text-total">{search?.numBuckets}</span> items - Viewing items
                <span className="start">{start + 1}</span>
                to {' '}
                <span className="end">
                    {Math.min(start + numPerPage, search?.numBuckets)}
                </span>
            </div>
            <div className="c-featurePager__navButtons">
                <span
                    onMouseDown={() => setPage(0)}
                    className="icon u-icon__arrow-end-left c-pagerIcon__buttonFirst"
                >
                    {' '}
                </span>
                <span
                    onMouseDown={() => {calcPage(-1)}}
                    className="icon u-icon__arrow3-left c-pagerIcon__buttonPrev"
                >
                    {' '}
                </span>
                <span className="c-pager__counterWrapper">
                    Page
                    <span className="c-pager__counter-1">
                        <NumericInput
                            aria-label="Goto page"
                            min={1}
                            max={9999}
                            size={input_size}
                            value={page + 1}
                            mobile={false}
                            noStyle={true}
                        />
                    </span>
                    of
                    <span className="c-pager__counterMax">{maxPage}</span>
                </span>
                <span
                    onMouseDown={() => { calcPage(1); }}
                    className="icon u-icon__arrow3-right c-pagerIcon__buttonNext"
                >
                    {' '}
                </span>
                <span
                    onMouseDown={() => calcPage('max')}
                    className="icon u-icon__arrow-end-right c-pagerIcon__buttonLast"
                >
                    {' '}
                </span>
            </div>
            {props.loadingState ? <span> loading...</span> : <span></span>}
            <div className="c-featurePager__itemCount">
                <span>Items per page</span>
                <NumericInput
                    aria-label="Set number of items per page"
                    min={25}
                    max={100}
                    size={3}
                    step={25}
                    // style={{width: "4em"}}
                    value={numPerPage}
                    onChange={(ps) => {
                        setPerPage(ps);
                    }}
                    mobile={false}
                    style={{
                        'input.mobile': {
                            border: '0px',
                        },
                        'btnUp.mobile': {},
                    }}
                />
            </div>
        </div>
    );
}
