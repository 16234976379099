import React, { useEffect, useRef, useState } from "react";
import { getBiblLang, getEdResource } from "../catalog/catalogUtils";
import { useBiblStore } from "../hooks/useBiblStore";
import "./TextStyled.scss";
import {usePagerText} from "../hooks/usePagerText";
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import $ from 'jquery';
import {useInfiniteSection} from "../hooks/useInfiniteSection";
import {useParams} from "react-router-dom";
import {HtmlCustom} from "../common/HtmlCustom";
import {useSearchStore} from "../hooks/useSearchStore";

/**
 * TextReaderText : for marked up, structured digital texts with IDed sections and possible multiple milestones
 *
 * @returns {JSX.Element}
 * @constructor
 */
export function TextReaderText() {
  const biblStore = useBiblStore();
  const bibl = biblStore.doc;
  const pager = usePagerText();

  let { sid } = useParams();

  // useInfiniteSection loads more content as the Load More button scrolls into view
  const {
    data: pgdata,
    error: fetchingError,
    isFetching: isFetchingPages,
    ifFetchingPreviousPage: isFetchingPreviousSection,
    isFectionNextPage: isFetchingNextSection,
    hasNextPage: hasNextSection,
    hasPreviousPage: hasPreviousSection,
    fetchNextPage: fetchNextSection,
    fetchPreviousPage: fetchPreviousSection,
    status: fetchingStatus,
  } = useInfiniteSection(pager?.section);

  const rootRef = useRef();
  const loadMoreButtonRef = useRef();

  useIntersectionObserver({
    root: rootRef,
    target: loadMoreButtonRef,
    onIntersect: fetchNextSection,
    enabled: hasNextSection,
    threshold: 0.5,
  });

  useEffect(() => {
    // Set usePager.loaded to a list of page keys. For later scrolling, to determine if already loaded
    pager.setLoaded(
        pgdata?.pages?.map((sect, si) => {
          return sect?.sid;
        })
    );
    // Make sure at least 3 sections are loaded
    if (pgdata?.pages?.length < 5) {
      if (hasPreviousSection) {
        fetchPreviousSection();
      }
      if (hasNextSection) {
        fetchNextSection();
      }
    }
  }, [pgdata]);

  useEffect(() => {
      pager?.setSection(sid);
  }, [sid]);


    // Highlights TOC on scroll (can't use intersection observers because subsections in cutsom markup)
    const checkVisible = () => {
        if (window?.checkvisible) { return; }
        window.checkvisible = setTimeout( function () {
            try {
                const trt = $('#text-reader-text');
                const partop = trt?.offset()?.top;
                const parbottom = trt?.height() + partop;
                const sels = $(".c-text-section, .c-text-subsection");
                const vbls = [];
                let lastone = '';
                $.each(sels, function (si, s) {
                    let sel = $(s);
                    let seltop = sel.offset().top;
                    if (seltop > partop - 120 && seltop < parbottom) {
                        vbls.push(sel.attr('id').replace('s-', ''));
                    }
                    if (seltop < parbottom) {
                        lastone = sel.attr('id').replace('s-', '');
                    }
                });
                if (vbls.length === 0) {
                    vbls.push(lastone);
                }
                pager.updateVisible(vbls);
                window.clearTimeout(window.checkvisible);
                window.checkvisible = null;
            } catch (e) {
                console.log("Error", e);
            }
        }, 800);
    };

  useEffect(() => {
      checkVisible();
  }, []);

  if (fetchingStatus === "loading") {
    return <p>Loading...</p>;
  }
  if (fetchingError || fetchingStatus === "error") {
    console.log(fetchingError);
    return <p>Oops! Something went wrong loading Text Reader sections!</p>;
  }

  if (!bibl?.hastext) {
    return (
      <div id="text-reader-text" className="c-text-view">
        <p>There is no digital text for this work.</p>
      </div>
    );
  }


  const mylang = getBiblLang(bibl);
  const cn = 'c-text-view ' + mylang;
  // console.log("data for " + pager?.section, data);


  return (
    <div id="text-reader-text" className={cn} ref={rootRef} onScroll={checkVisible}>
        {pgdata.pages.map((s, si) => {
          return <TextSection key={`ts-${si}-${s.sid}`} doc={s} />;
        })}

      <div className="infinite-load-btn">
        <button
            ref={loadMoreButtonRef}
            className={isFetchingNextSection
                ? "loading"
                : hasNextSection
                    ? "canload"
                    : "finis"}
            onClick={() => fetchNextSection()}
            disabled={!hasNextSection || isFetchingNextSection}
        >
          {isFetchingNextSection
              ? "Loading more..."
              : hasNextSection
                  ? "Load More"
                  : "————————————"}
        </button>
      </div>
      <div>{isFetchingPages && !isFetchingNextSection ? "Fetching..." : null}</div>
    </div>

  );
}

function TextSection({ doc }) {
    const search = useSearchStore();
    // console.log(search?.query);
    const qstr = search?.query?.replace(/"/g, '');
    const rep = new RegExp( qstr, 'g');
    const sectRef = useRef(null);
    // console.log("text section doc", doc);
    let sectmu = doc?.section
    if(qstr?.length > 0) { sectmu = sectmu.replace(rep, `<span class="highlight">${qstr}་</span>`); }
    const tid= doc.tid;
    const sid = doc.sid;
    return (
        <div
          id={`s-${sid}`}
          className={`c-text-section`}
          data-id={sid}
          ref={sectRef}
        >
            <HtmlCustom markup={sectmu} key={`${tid}-${sid}`} />
        </div>
    );
}
