import React, {useEffect, useState} from "react";
import {useSearchStore} from "../hooks/useSearchStore";
import {useSolr} from "../hooks/useSolr";
import THLSkeleton from "../common/THLSkeleton";
import Form from 'react-bootstrap/Form';
import {useCookies} from "react-cookie";

// Facet labels are a dictionary that connects a facet machine name with its display label.
// If not found as a key in this dictionary, the machine name is used as the default label.
const facetlabels = {
    'chapter': 'chapter title',
    'publication_stmt': 'publication info'
};

/**
 * The overall search facet div placed in a sidebar
 *
 * @param props
 * @returns {JSX.Element|null}
 */
export default function SearchFacets(props) {
    const search = useSearchStore();
    // console.log(search);
    const [ftypes, facets] = search?.getFacets();
    if (!facets || !ftypes) { return null; }
    return (
        <div>
            {
                Object.entries(ftypes).map(( [k, fname], fti) => {
                    return <FacetBox key={[k, fti]} name={k} label={fname} data={facets[k]} />
                })
            }

        </div>
    )
}

/**
 * A facet box displays the result for a single facet, such as types. It shows all the values for the facet as choices
 *
 * @param name
 * @param label
 * @param data
 * @returns {JSX.Element|null}
 * @constructor
 */
function FacetBox({ name, label, data }) {
    if (!data || !Object.keys(data)) { return null; }
    // Filter out blank keys and sort
    const fkeys = Object.keys(data).filter(fk => fk).sort();
    let facets = fkeys.map((fk, fki) => {
        const flab =  (Object.keys(facetlabels).includes(fk)) ? facetlabels[fk] : fk;
        return <li key={["facets", name, fki]} className="list-unstyled">
            <FacetItem facetitem={fk} facet={name} label={flab} count={data[fk]}/>
        </li>;
    });
    return (
        <>
            <h3>{label}</h3>
            {facets}
        </>
    );
}

/**
 * Displays a facet item in the list of search facets with a checkbox, label, and hitcount
 *
 * @param facet
 * @param facetitem
 * @param label
 * @param count
 * @returns {JSX.Element}
 * @constructor
 */
function FacetItem({facet, facetitem, label, count}) {
    const fq = `${facet}:${facetitem}`;
    const ss = useSearchStore();
    const filters = ss.filters;
    const [cookies, setCookie, removeCookie] = useCookies(['thlcat_searchfilters']);
    // console.log(filters);
    const [checked, setChecked] = useState(ss.hasFilter(fq));
    const toggleFilter = (e) => {
        const facet = e.target.value;
        if (!checked) {
            ss.addFilter(facet)
            setChecked(true);
        } else {
            ss.removeFilter(facet)
            setChecked(false);
        }
        setCookie('thlcat_searchfilters', JSON.stringify(ss.filters));
    };

    return <><Form.Check
        type="checkbox"
        id={`filter-${facetitem}`}
        label={label}
        value={fq}
        inline
        checked={checked ? "checked" : false}
        onChange={toggleFilter}
    /> (<FacetCount facet={facet} facetitem={facetitem} hitcount={count} />)</>;
}

/**
 * Returns the number of hits for that facet whether selected or not.
 * If it's not selected, does a separate search to find the number of hits
 *
 * @param facet
 * @param facetitem
 * @param hitcount
 * @returns {JSX.Element}
 * @constructor
 */
function FacetCount({facet, facetitem, hitcount}) {
    const query = useSearchStore((state) => state.query);
    const fq = `${facet}:${facetitem}`;
    const params = {
        q: query,
        fq: [fq],
        rows: 0
    }
    const {
        isLoading: isCollInfoLoading,
        data: collInfo,
        isError: isCollInfoError,
        error: collInfoError,
    } = useSolr(params);

    if (isCollInfoError || isCollInfoLoading) {
        return <>-</>;
    }
    if (hitcount * 1 > 0) {
        return <>{hitcount}</>
    }

    return <>{collInfo.numFound}</>
}
